.mainTbl {
}
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #0090e7 !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #0090e7 !important;
  color: #fff;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 8px 16px;
}
.scroll {
  height: 708px;
  overflow: auto;
  text-align: justify;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 10px;
  margin: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #0090e7;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0090e7;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-size: 11px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px !important;
}
.ant-drawer .ant-drawer-header {
  padding: 5px 24px;
}
.ant-drawer .ant-drawer-body {
  padding: 5px 24px 24px 24px;
}

.ant-card .ant-card-body {
  padding: 0px !important;
}

.ant-card .ant-card-head {
  padding: 0px 6px !important;
}

/* .ant-modal .ant-modal-content{
    padding: 10px 0px !important;
   } */
.mapShowModal .ant-modal .ant-modal-content {
  padding: 0px !important;
}
.ant-select-dropdown .ant-select-item-option-content {
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: initial !important;
}

::-webkit-scrollbar {
  width: 6px;
}

.ant-image-mask {
  display: none !important;
}

.ant-image-preview {
  display: none !important;
}
.ant-image-preview-mask {
  display: none !important;
}
.ant-image-preview-wrap {
  display: none !important;
}
.ant-image-preview-operations-wrapper {
  display: none !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
/* .ant-modal-wrap  {
        width: 35% !important;
        position: absolute !important;
        right: 10px !important;
        margin-left: 64% !important;
    } */

.liveTrackingModalCss {
  position: relative;
  top: 10px;
  right: 0;
  height: 95% !important;
}
.ant-modal-root .ant-modal-wrap .liveTrackingModalCss {
  width: 100% !important;
}

.liveTrackingModalCss .ant-modal-content {
  padding: 15px 15px !important;
  background-color: rgba(24, 23, 34, 0.5);
  box-shadow: -1px 1px 4px 1px rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(2px);
}
.liveTrackingModalCss .ant-modal-header,
.ant-modal-title,
.ant-modal-close-x {
  background-color: transparent;
}

.formShowModal .ant-modal-title {
  color: rgba(0, 0, 0, 0.8) !important;
}
.ant-modal-mask .liveTrackingModalCss {
  display: none !important;
}
.liveTrackingModalCss .ant-modal-title {
  color: #ffffff !important;
}

.liveTrackingMainTbl .ant-table-tbody {
  background-color: white;
  height: 80%;
  overflow: scroll;
}
/* .liveTrackingMainTbl  table{table-layout: auto !important;} */
.liveTrackingTimerCss {
  padding-inline-start: 10px;
  border-radius: 6px;
  height: 36px;
  line-height: 37px;
  font-size: 13px;
  justify-content: space-between;
  display: flex;
  width: 34%;
  margin: 0;
}
.liveTrackingTimerCss p {
  margin-inline-end: 5px;
}
.liveTrackingTimerCss .refresh-btn {
  background: #0090e7;
  border-start-start-radius: 0;
  border-end-end-radius: 6px;
  color: #ffffff;
  padding: 0 5px;
  border-start-end-radius: 6px;
}
.liveTrackingTimerCss .refresh-btn svg {
  margin: -1px 0 0 0;
}
.liveTrackingTimerCss .ant-select .ant-select-selector {
  width: 58px;
  border: none;
  top: 12px;
  background: #eeeeee;
  margin-inline-start: 5px;
  font-size: 13px;
  border-radius: 0;
  height: 36px !important;
}
.liveTrackingModalCss .ant-modal-body {
  height: 650px !important;
}

.slider-container {
  z-index: 999;
  display: block;
  align-items: flex-start;
  position: absolute;
  inset-inline-end: 36.2%; /*36 %*/
  bottom: 4%;
}
.liveTrackingTableBtn {
  display: flex;
  flex-direction: column;
}
.liveTrackingTableBtn .circleBtn {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
}
.liveTrackingTableBtn .circleBtn:hover {
  outline: 0;
  border: 0;
}
.liveTrackingTableBtn .circleBtn span {
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
  color: #ffffff;
  padding: 0 5px;
  background: #ffffff;
  border: solid 2px #e9e9e9;
  font-size: 9px;
  color: #000000;
  text-align: left;
  position: absolute;
  inset-inline-end: 0;
  width: auto;
  padding-inline-end: 10px;
  right: -1px;
}
.liveTrackingTableBtn .circleBtn.idleBtn {
  background: #e6b508;
}
.liveTrackingTableBtn .circleBtn.runningBtn {
  background: #115f0a;
}
.liveTrackingTableBtn .circleBtn.nodataBtn {
  background: #8f8f8f;
}
.liveTrackingTableBtn .circleBtn.stopBtn {
  background: #ac0000;
}
.liveTrackingTableBtn .circleBtn.allBtn {
  background: #000000;
  margin: 0;
}
.whiteBtn {
  background: #ffffff;
  border: solid 1px #8f8f8f;
  border-radius: 5px;
  text-align: center;
  width: 38px !important;
  height: 32px;
  padding: 0 !important;
  margin: 0 0 8px 4px;
}

.right-panel-btn-group {
  z-index: 999;
  top: 20px;
  inset-inline-end: 36.3%; /*36.3*/
  height: 245px;
}
.right-panel-btn-group-old {
  z-index: 9999;
  inset-inline-end: 19px;
  height: 245px;
  inset-block-start: 55px;
}
.arabic-map-container .right-panel-btn-group-old {
  inset-inline-end: 13px;
}
/* .leaflet-left {
    right: 38.3%;
    left: auto;
    top: 276px
} */

.right-menu-panel-newGeofence {
  background: #ffffff;
  border: solid 1px #cbcbcb;
  padding: 15px;
  border-radius: 5px;
  position: absolute;

  /* left: 16%; */
  top: 6%;
  width: 25%;
  height: auto;
  inset-inline-end: 5%;
}
.right-menu-panel {
  background: #ffffff;
  border: solid 1px #cbcbcb;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  inset-inline-end: 5%;
  top: 5%;
  width: 25%;
}
.autocomplete-location-textbox {
  background: #fcfcfc;
  border: solid 1px #cbcbcb;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  /* color: #969696; */
  padding: 0 10px;
  height: 40px;
}
.right-menu-panel .ant-card-head {
  border: 0;
  padding: 0 15px 0 0 !important;
}
.right-menu-panel .ant-form-item {
  margin: -15px 0 0 0;
}
.right-menu-panel .ant-form-item-row {
  margin: -8px 0 0 0;
}
.right-menu-panel .checkbox-block {
  margin: 15px 0 0 0;
}
.right-menu-panel .ant-form-item-label {
  bottom: -17px;
}
.right-menu-panel .ant-card-head-title h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.rightPanel-black {
  padding: 15px 15px !important;
  background-color: rgba(24, 23, 34, 0.5);
  box-shadow: -1px 1px 4px 1px rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(2px);
  border-radius: 5px;
  position: absolute;
  inset-inline-end: 1%;
  top: 20px;
  width: 35%;
  height: 95%;
  border: 0;
}
.rightPanel-black .ant-card-head {
  border: 0;
  min-height: auto;
}
.rightPanel-black .ant-card-head-title {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 10px 0;
}
.right-menu-panel.headerGroup-panel .ant-card-head {
  padding: 0 !important;
  min-height: auto !important;
  margin: 0 0 15px 0 !important;
}
.right-menu-panel.showgeofence .deleteBtn {
  width: 45px;
  height: 35px;
  background-color: #1677ff;
  color: white;
  border: 0;
}
.right-menu-panel.showgeofence .deleteBtn i {
  font-size: 16px;
}
.right-menu-panel .list-bottom-btns {
  display: flex;
  justify-content: flex-end;
}
.right-menu-panel .list-bottom-btns button {
  background: #0090e7;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 100%;
  margin: 10px 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-menu-panel .list-bottom-btns button i,
.right-menu-panel .list-bottom-btns button .anticon-plus {
  font-size: 22px;
}
.right-menu-panel .ant-form-item.form-fields {
  margin: 0;
}
.distance-total-block {
  background: #f4f4f4;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 15px 0 0 0;
}
.distance-total-block p {
  margin: 0;
  padding: 5px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}
.distance-total-block p b {
  font-weight: 600;
}
.distance-total-block p:last-child {
  border: 0;
}
.lbl-field {
  position: relative;
  margin: 0 0 18px 0;
}
.lbl-field span {
  position: absolute;
  top: -11px;
  background: white;
  font-size: 13px;
  padding: 2px;
  left: 15px;
}
.radioBtnGroup {
  margin: 15px 0 0 0;
}
.radioBtnGroup span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.88);
  display: block;
}
.radioBtnGroup .ant-radio-group {
  margin: 5px 0 0 0;
}
.centerWhite-modal {
  padding: 15px 15px !important;
  background: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  width: 21%;
  margin: 0 auto;
  position: relative;
  top: -520px;
  /* bottom: 6%; */
  left: -28%;
  transform: translate(-50%, -50%);
  border: solid 2px rgba(24, 23, 34, 0.7);
  border-radius: 10px;
}
.centerWhite-modal .ant-card-head {
  border: 0 !important;
  padding: 0 !important;
  min-height: auto !important;
}
.blur-input label {
  backdrop-filter: blur(8px) !important;
}
.blur-input .ant-select-selector,
.blur-input .ant-picker {
  backdrop-filter: blur(8px) !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: solid 1px #181722 !important;
}
.blur-input .ant-select .ant-select-arrow,
.blur-input .ant-picker .ant-select-arrow,
.blur-input .ant-picker .ant-picker-suffix {
  color: #181722 !important;
}
/* left panel css */

.left-panel {
  background: #ffffff;
  padding: 15px;
  border-radius: 0;
  box-shadow: 2px 0 3px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.left-panel.leftSlide {
  left: 13%;
}
.sml-search-input .ant-select-selector,
.sml-search-input .ant-input {
  height: 40px !important;
}
.sml-search-input .ant-select-selector .ant-select-selection-placeholder,
.sml-search-input .ant-select-selector .ant-select-selection-item {
  line-height: 40px !important;
}
.sml-search-input .ant-select-selector .ant-select-selection-search {
  margin: 5px 0 0 0 !important;
}
.multiTracking-card {
  border-radius: 10px;
}
.multiTracking-card {
  border: solid 1px #0090e7;
}
.multiTracking-card .ant-card-head {
  background: #0090e7;
}
.multiTracking-card .ant-card-head {
  color: #ffffff !important;
  min-height: 41px;
}
.multiTracking-card .title {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}
.multiTracking-card .title .ant-btn {
  background-color: transparent;
  border: 0;
}
.multiTracking-card .title .ant-btn i {
  color: #ffffff;
}
.multiTracking-overlay {
  background-color: rgba(24, 23, 34, 0.5) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 99999999999999 !important;
}
.left-panel .ant-card-bordered,
.left-panel .formBtnGroup {
  border: 0 !important;
}
.multiTracking-popup .ant-modal-content {
  padding: 0;
}
.multiTracking-popup .ant-modal-header {
  background: #0090e7;
  margin: 0 !important;
  min-height: 41px;
}
.multiTracking-popup .ant-modal-header .ant-modal-title {
  color: #ffffff !important;
  line-height: 41px;
  padding: 0 15px;
}
.multiTracking-popup .ant-modal-content .ant-modal-close {
  display: none;
}
.multiTracking-popup .ant-modal-content .anticon-close {
  position: absolute;
  inset-inline-end: 15px;
  color: #ffffff;
  top: 13px;
}
.multiTracking-popup .ant-modal-body .bigMap {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  width: 100% !important;
  height: 92% !important;
  border-radius: 0 0 10px 10px;
}
.ant-select-selector input {
  height: 100% !important;
}
/* .ant-select-selection-search-input {
    height: 12px !important;
} */

.vehicle-details-card {
  border-radius: 5px;
  border: 0;
  background: rgba(24, 23, 34, 0.7);
}
.vehicle-details-card .ant-card-head {
  background: #0090e7;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
  border: 0;
}
.vehicle-details-card .ant-card-head {
  color: #ffffff !important;
  min-height: 41px;
  font-size: 15px;
  font-weight: 600;
}
.vehicle-details-card .ant-card-head i {
  color: #ffffff;
}
.vehicle-details-card .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicle-details-card .title .ant-btn {
  background-color: transparent;
  border: 0;
}
.vehicle-details-card .title .ant-btn i {
  color: #ffffff;
}
.vehicle-details-card .ant-card-body {
  padding: 10px !important;
}
.vehicle-details-card .vehicle-type-img {
  text-align: center;
  margin: 10px 0;
}
.vehicle-details-card .vehicle-type-img img {
  margin: 0 auto;
}
.vehicle-details-card .driver-info {
  margin: 0 0 10px 0;
}
.vehicle-details-card .driver-info p {
  background: rgba(24, 23, 34, 0.7);
  padding: 3px 10px 3px 20px;
  text-align: left;
  margin: 0 0 5px 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}
.vehicle-details-card .driver-info p i {
  margin: 0 20px 0 0;
  font-size: 22px;
}
.vehicle-details-card .driver-info p i.bi-telephone {
  font-size: 20px;
}
.vehicle-details-card .white-row {
  background: #ffffff;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 0 10px 0;
}
.vehicle-details-card .white-row.vehicle-status {
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}
.vehicle-details-card .white-row.vehicle-status small {
  text-align: right;
  font-size: 15px;
  font-weight: 400;
}
.vehicle-details-card .white-row.vehicle-status .status {
  font-size: 15px;
  font-weight: 400;
  font-weight: 600;
  text-align: left;
}
.vehicle-details-card .white-row.vehicle-status .status.idle {
  color: #abae10;
}
.vehicle-details-card .white-row.vehicle-status .status.running {
  color: #00934b;
}
.vehicle-details-card .white-row.vehicle-status .status.stop {
  color: #f86458;
}
.vehicle-details-card .white-row.vehicle-status .status.nodata {
  color: #a3a2b6;
}
.vehicle-details-card .white-row.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicle-details-card .white-row.notification i {
  color: #0090e7;
  font-size: 20px;
}
.vehicle-details-card .white-row.notification small {
  text-align: right;
  font-size: 15px;
  font-weight: 400;
}
.vehicle-details-card .white-row.location .location-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicle-details-card .white-row.location h3 {
  color: #131313;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.vehicle-details-card .white-row.location i {
  color: #000000;
  font-size: 18px;
}
.vehicle-details-card .white-row.location p {
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  padding: 0;
}
.vehicle-details-card .white-row.location small {
  font-size: 10px;
  color: #484848;
  font-weight: 500;
}
.vehicle-details-card .white-row.share-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicle-details-card .white-row.share-box i {
  color: #0090e7;
  font-size: 18px;
}
.vehicle-details-card .white-row h3 {
  color: #131313;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.vehicle-details-card .white-row.total-activity p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282828;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
}
.vehicle-details-card .white-row.total-activity p span {
  text-align: left;
}
.vehicle-details-card .white-row.total-activity p span:first-child {
  width: 10%;
}
.vehicle-details-card .white-row.total-activity p.kms {
  border-bottom: solid 1px #282828;
  font-size: 16px;
  color: #282828;
  font-weight: 600;
}
.vehicle-details-card .white-row.total-activity i {
  font-size: 18px;
  color: #abae10;
}
.vehicle-details-card .white-row.total-activity i:last-child {
  color: #00a92f;
}

.vehicle-details-card .white-row.driver-performance p.performance-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
}
.vehicle-details-card .white-row.driver-performance p.performance-status.harsh {
  color: #00934b;
}
.vehicle-details-card
  .white-row.driver-performance
  p.performance-status.overspeed {
  color: #fab441;
}
.vehicle-details-card
  .white-row.driver-performance
  p.performance-status.exidle {
  color: #f86458;
}
.vehicle-details-card
  .white-row.driver-performance
  p.performance-status.harsh-acceleration {
  color: #a3a2b6;
}
.vehicle-details-card
  .white-row.driver-performance
  p.performance-status.accident-count {
  color: #a59065;
}
.vehicle-details-scroll {
  height: 355px;
  overflow-y: scroll;
  width: 95%;
  padding-right: 5px;
  position: absolute;
}
.ant-popover.ant-popover-placement-rightTop .ant-popover-inner {
  background-color: transparent !important;
  box-shadow: none !important;
}
.ant-popover .ant-popover-inner {
  padding-block-start: 12px !important;
  padding-block-end: 12px !important;
  padding-inline-start: 12px !important;
  padding-inline-end: 12px !important;
  /* padding: 0 !important; */
}

.assign-trip-details-panel {
  position: absolute;
  left: 7%;
  top: 4%;
  width: 60%;
}
.leftWhite-form-panel {
  background: #ffffff;
  border-radius: 10px;
  border: solid 1px #000000;
  width: 25%;
  padding: 0 15px 15px 15px;
  position: relative;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.1);
  height: 725px;
  overflow-y: auto;
}
.leftWhite-form-panel .plusBtn {
  background: #fcfcfc;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  position: absolute;
  right: 15px;
}
.leftWhite-form-panel .plusBtn i {
  color: #0090e7;
  font-size: 25px;
}
.leftWhite-form-panel .add-location {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftWhite-form-panel .add-location input {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 40px;
  padding: 0 11px;
}
.leftWhite-form-panel .addBlue-btn.ant-btn-primary {
  width: 98% !important;
  height: 40px !important;
  margin: 0 0 0 10px;
}
.leftWhite-form-panel .location-list-wrapper {
  margin: 30px 0 0 0;
}
.leftWhite-form-panel .location-list-wrapper .location-list {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-bottom: solid 1px #919191;
  padding: 5px 0;
  margin: 0 0 10px 0;
}
.leftWhite-form-panel .location-list-wrapper .location-list span {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: solid 1px #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin: 0 10px 0 0;
}
.leftWhite-form-panel .location-list-wrapper .location-list .locationDate p {
  margin: 0 0 0 0;
  padding: 0;
  font-size: 14px;
  color: #363636;
  font-weight: 600;
  line-height: 100%;
}
.leftWhite-form-panel .location-list-wrapper .location-list .locationDate b {
  font-size: 12px;
  color: #363636;
  font-weight: 400;
}
.leftWhite-form-panel
  .location-list-wrapper
  .location-list
  .locationDate
  small {
  font-size: 10px;
  color: #919191;
  font-weight: 400;
  display: block;
}
.leftWhite-form-panel .location-list-wrapper .location-list i {
  color: #ec0000;
  position: absolute;
  right: 0;
  bottom: 10px;
}
/* .leaflet-draw leaflet-control{left: 110vh !important} */
.leaflet-left .leaflet-control {
  /* margin-left: 10px !important; */
  /* left: 18vh !important; */
  /* top:5vh !important; */
  /* position: relative !important; */
  /* z-index: 999 !important; */
  /* left: 85vh; */
  /* position: fixed; */
  right: -30px;
  position: relative !important;
  z-index: 999 !important;
  left: 62vh;
  position: fixed;
  top: 36px;
}
.leaflet-right .leaflet-draw.leaflet-control {
  /* margin-left: 10px !important; */
  /* left: 18vh !important; */
  /* top:5vh !important; */
  /* position: relative !important; */
  /* z-index: 999 !important; */
  /* left: 85vh; */
  /* position: fixed; */

  position: relative !important;
  z-index: 999 !important;
  right: 62.5vh;
  position: fixed;
  top:-13px;
}
/* .leaflet-control-zoom-in,.leaflet-control-zoom-out{display: none !important;} */

.live-routepath-modal {
  padding: 15px;
  /* border-radius: 5px; */
  position: absolute;
  left: 13%;
  top: 5%;
  width: 25%;

  /* background-color: hsla(0,0%,100%,.5); */
  /* border: 2px solid rgba(24, 23, 34, 0.7); */
  border-radius: 10px;
  backdrop-filter: blur(6px);
}

/*.leaflet-touch .leaflet-bar {
    inset-inline-end: 10px;
    margin-right: 0;
}*/
.leaflet-bar {
  inset-inline-end: 10px;
  margin-right: 0;
}
.arabic-map-container .leaflet-bar {
  inset-inline-start: 18px;
  float: left;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.vehicle-info-panel {
  padding: 0 !important;
  background-color: rgba(24, 23, 34, 0.5);
  box-shadow: -1px 1px 4px 1px rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(2px);
  border-radius: 5px;
  border: 0;
}
.vehicle-info-panel .ant-card-head {
  background: #0090e7;
  box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 2);
  padding: 5px 10px !important;
  border-radius: 5px 5px 0 0;
}
.vehicle-info-panel .ant-card-head .ant-card-head-title {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
}
.vehicle-info-panel .ant-card-head .ant-card-head-title div {
  display: flex;
  justify-content: space-between;
}
.vehicle-info-panel .ant-card-body {
  padding: 15px !important;
}
.vehicle-info-panel .ant-card-body .ant-tabs-nav-list {
  height: 38px;
}
.vehicle-info-panel .ant-card-body .ant-tabs-tab {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 15px;
  color: #ffffff;
  margin-inline-end: 10px;
}
.vehicle-info-panel .ant-card-body .ant-tabs-tab:nth-child(4) {
  margin: 0;
}

.vehicle-info-panel .ant-card-body .ant-tabs-tab.ant-tabs-tab-active {
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff !important;
}
.vehicle-info-panel .ant-card-body .ant-tabs-nav::before {
  border-bottom: 1px solid #ffffff;
}
.vehicle-info-panel .ant-card-body .ant-tabs-tab::after {
  background: transparent;
  height: 3px;
  left: 0;
  right: 0;
  bottom: -8px;
  content: "";
  position: absolute;
  transition: all ease 0.5s;
}
.vehicle-info-panel .ant-card-body .ant-tabs-tab.ant-tabs-tab-active::after {
  background: #0090e7;
}
.vehicle-info-panel
  .ant-card-body
  .ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ffffff;
  text-shadow: none;
}
.vehicle-info-panel .ant-card-body .vehicle-info-address {
  color: #ffffff;
  margin: 0 0 5px 0;
}
.vehicle-info-panel .ant-card-body .ant-tabs-content-holder {
  padding: 0 5px;
}
.vehicle-info-panel .ant-card-body .vehicle-info-address p i {
  font-size: 23px;
  margin-inline-end: 8px;
  color: #0090e7;
}
.vehicle-info-panel .ant-card-body .vehicle-info-address p {
  font-size: 13px;
  text-align: left;
  line-height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
  color: #000000;
}

.vehicle-info-panel .ant-card-body .vehicle-info-list {
  margin: 0 0;
  list-style-type: none;
  padding: 0;
}
.vehicle-info-panel .ant-card-body .vehicle-info-list li {
  background: #eeeeee;
  padding: 8px;
  color: #000000;
  font-size: 13px;
  margin: 0 0 8px 0;
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.vehicle-info-panel .ant-card-body .hist-datepicker {
  margin: 10px 0 0 0;
}
.vehicle-info-panel .ant-card-body .hist-datepicker .ant-picker {
  background: transparent;
  border: solid 1px #ffffff;
}
.vehicle-info-panel
  .ant-card-body
  .hist-datepicker
  .ant-picker.ant-picker-focused {
  border-color: #ffffff;
}
.vehicle-info-panel .ant-card-body .hist-datepicker .ant-picker input {
  color: #ffffff;
  font-size: 13px;
}
.vehicle-info-panel
  .ant-card-body
  .hist-datepicker
  .ant-picker
  input::placeholder {
  color: #ffffff;
}
.vehicle-info-panel .ant-card-body .hist-datepicker .ant-picker svg {
  color: #ffffff;
}
.summary-info-block {
  margin: 15px 0 0 0;
  margin-inline-end: 5px;
}
.summary-info-block .title {
  background: #0090e7;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0 10px 0;
}
.summary-info-block .summary-content-block,
.summary-info-block .summary-duration-block {
  background: #eeeeee;
  border-radius: 5px;
  padding: 8px;
}
.summary-info-block .summary-content-block p {
  display: flex;
  justify-content: space-between;
  color: #000000;
  line-height: 16px;
  margin: 0 0 10px 0;
}
.summary-info-block .summary-content-block p span {
  text-align: right;
  font-size: 13px;
  font-weight: 500;
}
.summary-info-block .summary-content-block p small {
  text-align: end;
  font-size: 12px;
  margin-inline-start: 8px;
}
.summary-info-block .summary-duration-block .duration-row {
  display: flex;
  justify-content: space-between;
  color: #000000;
  line-height: 16px;
  margin: 0 0 10px 0;
  align-items: center;
}
.summary-info-block .summary-duration-block .duration-row p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
.summary-info-block .summary-duration-block .duration-row p b {
  font-size: 13px;
  font-weight: 600;
  margin: 0 10px 0 0;
  width: 32%;
}
.summary-info-block .summary-duration-block .duration-row p {
  font-size: 12px;
  width: 70%;
}
.summary-info-block .summary-duration-block .duration-row p b.status-stop {
  color: #fc291c;
}
.summary-info-block .summary-duration-block .duration-row p b.status-start {
  color: #4ac325;
}
.summary-info-block .summary-duration-block .duration-row small {
  text-align: right;
  font-size: 12px;
}
.summary-info-block .summary-duration-block .lineDivider {
  width: 90%;
  margin: 15px auto;
  background: #a0a0a0;
  height: 1px;
}
.summary-info-block .summary-duration-block {
  margin: 0 0 15px 0;
}
.summary-info-block .summary-content-block p span.loc-title {
  width: 35%;
  text-align: start;
}
.vehicle-info-panel .ant-card-body .no-data-block {
  border: solid 1px #ffffff;
  padding: 8px 15px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
}
.hist-datepicker .ant-picker-suffix:hover span {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.leaflet-draw-toolbar a {
  background-size: 307px 30px;
}
.userMenu {
  width: 230px;
  position: fixed;
  inset-inline-start: 200px;
  top: 95px;
  z-index: 99999;
}
.userMenu.menuSml {
  inset-inline-start: 80px;
}
.leaflet-draw-toolbar a {
  background-size: 307px 30px;
}

.vehicleInformation {
  background: rgba(24, 23, 34, 0.7);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
}
.vehicleInformation .vehiclePic {
  width: 45%;
  margin-inline-end: 5%;
}
.vehicleInformation .vehicleDetails {
  width: 50%;
}
.vehicleInformation .vehicleDetails p {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  margin: 0 0 5px 0;
  border: solid 1px rgba(255, 255, 255, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 15px;
}
.vehicleInformation .vehicleDetails p i {
  font-size: 20px;
  margin-inline-end: 8px;
}
.vehicleInformation .vehicleDetails p i.bi-telephone {
  font-size: 17px;
}
.vehicleInformation .vehicleDetails p i.bi-broadcast-pin {
  font-size: 18px;
}
.vehicle-info-panel .ant-card-body .vehicle-info-address {
  background: #eeeeee;
  padding: 8px;
  border-radius: 5px;
  margin: 0 0 8px 0;
}
.vehicle-info-panel .ant-card-body .lat-long-block {
  font-size: 13px;
  color: #000000;
  background: #eeeeee;
  padding: 8px;
  border-radius: 5px;
  margin: 0 0 8px 0;
}

.vehicle-info-panel .ant-card-body .lat-long-block p {
  font-size: 13px;
  text-align: left;
  line-height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
}
.vehicle-info-panel .ant-card-body .lat-long-block p i {
  font-size: 20px;
  margin-inline-end: 8px;
  color: #0090e7;
}
.vehicle-info-panel .share-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  padding: 8px;
  border-radius: 5px;
}
.vehicle-info-panel .share-box i {
  color: #0090e7;
  font-size: 18px;
}

.summary-info-scroll {
  min-height: 520px;
  overflow-y: scroll;
  max-height: 520px;
}

@media screen and (min-width: 1920px) {
  .summary-info-scroll {
    min-height: 660px;
    max-height: 660px;
  }
}

.activityTab .white-row {
  background: #ffffff;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 0 10px 0;
}
.activityTab .white-row h3 {
  color: #131313;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.activityTab .white-row.total-activity p {
  align-items: center;
  color: #282828;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  justify-content: space-between;
  padding: 0 10px;
}

.activityTab .white-row.total-activity p.kms {
  border-bottom: 1px solid #282828;
  color: #282828;
  font-size: 16px;
  font-weight: 600;
}
.activityTab .white-row.total-activity i {
  color: #abae10;
  font-size: 18px;
}
.activityTab .white-row.total-activity i:last-child {
  color: #00a92f;
}
.activityTab .white-row.total-activity p span {
  text-align: left;
}
.activityTab .white-row.total-activity p span:first-child {
  width: 10%;
}

.activityTab .white-row.driver-performance p.performance-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
}
.activityTab .white-row.driver-performance p.performance-status.harsh {
  color: #00934b;
}
.activityTab .white-row.driver-performance p.performance-status.overspeed {
  color: #fab441;
}
.activityTab .white-row.driver-performance p.performance-status.exidle {
  color: #f86458;
}
.activityTab
  .white-row.driver-performance
  p.performance-status.harsh-acceleration {
  color: #a3a2b6;
}
.activityTab .white-row.driver-performance p.performance-status.accident-count {
  color: #a59065;
}

.statusPanelBtn {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 8px;
  padding: 5px;
  margin: 0 0 8px 0;
}
.rightPanel-black.trackingRightPanel .ant-card-head-title {
  margin: 0;
}
.ant-table .ant-table-tbody tr:first-child {
  height: 0 !important;
}



