@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');


.dash {
  position: relative;
  padding:25px 10px 10px 10px !important;
  width: 100%;
  max-width: 100%;
  font-size: var(--font-size-smi);
  color: var(--color-white);
  background: #e1e1e1;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.dash .leftPanel {
  padding: 0 30px 0 0 !important;
}


/* Right Alerts Boxes CSS */

.alerts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.alerts .card {
  background: transparent;
  border: 0px none;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: block;
  color: #ffffff;
}

.alerts .card .card-img {
  width: 76px;
}

.alerts .card .card-img-overlay {
  display: flex;
  padding: 10px;
}

.alerts .card .card-leftdata {
  width: 47%;
  display: flex;
  align-items: end;
}

.alerts .card .card-leftdata h5 {
  font-size: 18px;
  margin: 0;
}

.alerts .card .card-rightdata {
  text-align: right;
  width: 53%;
}
.alerts .card .card-rightdata img {
  width: auto;
}

.alerts .card .card-rightdata h2 {
  font-size: 30px;
  font-weight: 700;
}

.alerts .card .card-rightdata h2 span {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.alerts .card .card-rightdata p {
  margin: 0 0 8px 0;
}

.alerts .card .card-rightdata p span {
  display: block;
}



.overspeed {
  border-radius: var(--br-7xs);
  background-color: var(--color-salmon);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
  margin: 0 0 20px 0;
}

.insidegeofence {
  border-radius: var(--br-7xs);
  background-color: var(--color-darkslateblue-200);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}

.outsidegeofence {
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-600);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}

.power {
  border-radius: var(--br-7xs);
  background-color: var(--color-slategray);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
  font-size: var(--font-size-2xs);
}

.alertstriggered {
  border-radius: var(--br-7xs);
  background-color: var(--color-lightslategray-200);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}


.harshbreaking {
  border-radius: var(--br-7xs);
  background-color: var(--color-sienna);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}

.turning {
  border-radius: var(--br-7xs);
  background-color: var(--color-steelblue);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}


.acceleration {
  border-radius: var(--br-7xs);
  background-color: var(--color-darkolivegreen);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 170px;
  width: calc(25% - 12px);
}


.fleetstatus {
  height: 595px;
  padding: 16px;
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--color-darkslategray-100);
}

.ant-card-head {
  font-weight: 600;
  color: var(--color-gray-400);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
  border: 0;
  border: 0;
}
.ant-card-head {
  min-height: auto !important;
  border: 0;
}
.ant-card-head-title {
  font-weight: 600;
  font-size: var(--font-size-lg);
  color: var(--color-gray-400);
}

.fleetstatus-progress-head {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.total {
  position: relative;
}

.running {
  position: relative;
}

.stopped {
  position: relative;
}

.idle {}

.inactive {
  position: relative;
}

.nodata {
  position: relative;
}

.dash .total,
.dash .running,
.dash .stopped,
.dash .idle,
.dash .inactive,
.dash .nodata {
  margin: 15px 0 35px 0 !important;
}

.progress1,
.progress2,
.progress3,
.progress4,
.progress5,
.progress6 {
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke-100);
  width: 100%;
  height: 22px;
}

.divprogress-bar1,
.divprogress-bar2,
.divprogress-bar3,
.divprogress-bar4,
.divprogress-bar5,
.divprogress-bar6 {
  height: 21px;
  border-radius: 30px;
  width: 100%;
  background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

:root {
  --change2: 0%;
  --change3: 0%;
  --change4: 0%;
  --change5: 0%;
  --change6: 0%;

  --changeMS1: 0%;
  --changeMS2: 0%;
  --changeMS3: 0%;
  --changeMS4: 0%;
  --changeMS5: 0%;
  --changeMS6: 0%;
  --changeMS7: 0%;
  --changeMS8: 0%;
  --changeMS9: 0%;
  --changeMS10: 0%;
  --changeMS11: 0%;
  --changeMS12: 0%;
}

/* :root {
  --change2: 14.40677966101695%;
  --change3: 60.451977401129945%;
  --change4: 10.169491525423728%;
  --change5: 14.971751412429379%;
  --change6: 0%;
  --changeMS1: 41.25%;
  --changeMS2: 41.25%;
  --changeMS3: 40.833333333333336%;
  --changeMS4: 40.833333333333336%;
  --changeMS5: 40.833333333333336%;
  --changeMS6: 40.833333333333336%;
  --changeMS7: 40.833333333333336%;
  --changeMS8: 40.416666666666664%;
  --changeMS9: 40.416666666666664%;
  --changeMS10: 40.416666666666664%;
  --changeMS11: 40%;
} */

.progress-moved .divprogress-bar1 {
  width: 100%;
  background-color: #159895;
  animation: progressAnimation1 6s;
}

@keyframes progressAnimation1 {
  0% {
    width: 0%;
    background-color: #159895;
  }

  100% {
    width: 100%;
    background-color: #159895;
  }
}

.progress-moved .divprogress-bar2 {
  width: var(--change2);
  background-color: #377D01;
  animation: progressAnimation2 6s;
}

@keyframes progressAnimation2 {
  0% {
    width: 0%;
    background-color: #377D01;
  }

  100% {
    width: var(--change2);
    background-color: #377D01;
  }
}

.progress-moved .divprogress-bar3 {
  width: var(--change3);
  background-color: #981515;
  animation: progressAnimation3 6s;
}

@keyframes progressAnimation3 {
  0% {
    width: 0%;
    background-color: #981515;
  }

  100% {
    width: var(--change3);
    background-color: #981515;
  }
}

.progress-moved .divprogress-bar4 {
  width: var(--change4);
  background-color: #988315;
  animation: progressAnimation4 6s;
}

@keyframes progressAnimation4 {
  0% {
    width: 0%;
    background-color: #988315;
  }

  100% {
    width: var(--change4);
    background-color: #988315;
  }
}

.progress-moved .divprogress-bar5 {
  width: var(--change5);
  background-color: #939393;
  animation: progressAnimation5 6s;
}

@keyframes progressAnimation5 {
  0% {
    width: 0%;
    background-color: #939393;
  }

  100% {
    width: var(--change5);
    background-color: #939393;
  }
}

.progress-moved .divprogress-bar6 {
  width: var(--change6);
  background-color: #333235;
  animation: progressAnimation6 6s;
}

@keyframes progressAnimation6 {
  0% {
    width: 0%;
    background-color: #333235;
  }

  100% {
    width: var(--change6);
    background-color: #333235;
  }
}




.fleet-idle {

  margin: 0 10px 0 0px !important;
  height: 218px;
  color: var(--color-darkslategray-300);
  background-color: #ffff;
  border-radius: 6px;
  padding: 20px;
}

.parkpetrol {
  display: flex;
  margin: 20px 0 0 0;
}


.fleetidle {
  width: 50%;
}

.parkpetrol {
  display: flex;
  margin: 20px 0 0 0;
}

.park {
  display: flex;
}

.fuel-waste {
  display: flex;
}

.idleinfo {
  display: flex;
  margin: 20px 0 0 0;
}

.generally-an-idling {
  font-weight: 600;
  color: var(--color-darkslategray-100);
}

.note-generally-an-container {
  letter-spacing: 0.5px;
  line-height: 15.6px;
  align-items: center;
  margin: 8px 0 0 0;
}

/*.liters {
  font-weight: 600;
  line-height: 60px;
}*/
.liters {
  font-weight: 600;
  margin: 0 0 0 12px;
  font-size: 12px;
}

.div23,
.fuel-icon {}

.div23 {
  font-size: var(--font-size-18xl);
  letter-spacing: 0.5px;
  font-weight: 800;
  align-items: center;
  margin-left: 10px;
}

.fuel-icon {
  left: 0;
  width: 40px;
  height: 40px;
}

.approx-fuel-wastage {
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  line-height: 15.6px;
  font-weight: 600;
  color: var(--color-darkslategray-100);
  display: flex;
  align-items: center;
  width: 185.67px;
  height: 18px;
  font-size: 13px;
}

.petrol {
  color: var(--color-firebrick);
  width: 50%;
}

.hours3 {
  letter-spacing: 0.5px;
  font-weight: 600;
  align-items: center;
  color: #ce9500;
  margin: 0 0 0 12px;
  font-size: 12px;
}

.div24,
.parking-icon {
  height: 45px;
 /* width: 45px;*/
}

.div24 {
  font-size: var(--font-size-18xl);
  letter-spacing: 0.5px;
  font-weight: 800;
  align-items: center;
  color: #ce9500;
  margin-left: 10px;
}


.digitsBlock .div24, .digitsBlock .hours3 {
  display: block;
  height: auto;
  line-height: auto !important;
}

.idle-info-panel {
  margin:25px 0 0 0;
}
.total-fleet-idle {
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  line-height: 15.6px;
  font-weight: 600;
  color: var(--color-darkslategray-100);
  align-items: center;
  font-size: 13px;
}
.ant-card .ant-card-head {
  border: 0;
  
}

.jobinformation {
  display: flex;
}

.yellow {
  width: 33%;
  padding: 0px 5%;
}

.red {
  width: 33%;
  padding: 0px 5%;
}

.green {
  width: 33%;
  padding: 0px 5%;
}

.finished-1-icon {
  top: 0;
  left: 0;
  width: 91px;
  height: 103px;
  object-fit: cover;
}


.div20 {
  top: 34px;
  left: 24px;
  letter-spacing: 0.5px;
  line-height: 20.4px;
  font-weight: 800;
  display: flex;
  align-items: center;
  width: 45px;
  height: 37px;
}

@keyframes green {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

[role="green"] {
  --percentage: var(--value);
  --primary: #027200;
  --secondary: #adf;
  --size: 90px;
  animation: progress 6s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role="green"]::before {
  content: "";
  width: 90px;
  height: 90px;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role="green"]::after {
  position: absolute;
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
  font-size: calc(var(--size) / 5);
  color: var(--primary);
}

.canceled-1-icon {
  top: 0;
  left: 0;
  width: 89.22px;
  height: 96.73px;
  object-fit: cover;
}

.canceled-1-icon,
.div21,
.red {}

.div21 {
  top: 31.31px;
  left: 23.3px;
  letter-spacing: 0.5px;
  line-height: 20.4px;
  font-weight: 800;
  display: flex;
  align-items: center;
  width: 45px;
  height: 34px;
}

.finished {
  font-size: var(--font-size-smi);
  letter-spacing: 0.5px;
  line-height: 15.6px;
  font-weight: 600;
  align-items: center;
  margin-left: 10px;
}

.flag-icon {
  top: 60px;
  left: 280px;
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.canceled {
  font-size: var(--font-size-smi);
  letter-spacing: 0.5px;
  line-height: 15.6px;
  font-weight: 600;
  color: var(--color-firebrick);
  align-items: center;
  margin-left: 10px;
}

.cancelled-icon {
  top: 60px;
  left: 145px;
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.on-going {
  font-size: var(--font-size-smi);
  letter-spacing: 0.5px;
  font-weight: 600;
  color: var(--color-goldenrod-100);
  align-items: center;
  margin-left: 10px;
}

.jobinfo-icons {
  margin:10px 0 0 0;
  width: 15px;
  height: 15px;
  object-fit: cover;
}
@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #ce9500;
  --secondary: #adf;
  --size: 90px;
  animation: progress 6s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role="progressbar"]::before {
  content: "";
  width: 90px;
  height: 90px;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  position: absolute;
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
  font-size: calc(var(--size) / 5);
  color: var(--primary);
}
@keyframes red {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

[role="red"] {
  --percentage: var(--value);
  --primary: #bd0909;
  --secondary: #adf;
  --size: 90px;
  animation: progress 6s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role="red"]::before {
  content: "";
  width: 90px;
  height: 90px;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role="red"]::after {
  position: absolute;
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
  font-size: calc(var(--size) / 5);
  color: var(--primary);
}
.job-info {
  font-size: var(--font-size-18xl);
  color: var(--color-green-100);
  background-color: #ffff;
  border-radius: 6px;
  padding: 20px;
  margin: 0 0 0 8px;
}
.maxspeed-total-fleet,
.maxspeed-avgditance {
  padding-right: 10px;
}

.maxspeed-data {
  margin-top: 26px;
}
.vertical-number p {
  padding: 42px 0px 12px 0;
  font-size: var(--font-size-xs);
  font-weight: 600;
  border-bottom: 1px solid grey;
  margin: 0;
}
.bars {
  display: flex;
  justify-content: start;
  width: 90%;
  text-align: center;
  font-size: var(--font-size-xs);
  position: absolute;
  align-items: baseline;
  bottom:51px;
  /*right: 12px;*/
  left:38px;
}

.bar-row {}

.bar-row span {
  display: inline-block;
}

.bar-row strong {
  transform: rotate(-90deg);
  display: block;
  margin: 10px 8px 0 -3px;
  white-space: nowrap;
  position: absolute;
  top: 0;

}

.bar-row strong.vehicleNum-text {
  margin: 25px 8px 0 -20px;
}
.bar-row strong.vehicleNum-text-2 {
  margin: 25px 8px 0 -20px;
}
.bar-row strong.vehicleNum-text-2 {
  margin: 42px 8px 0 -29px;
}


.prog1,
.prog2,
.prog3,
.prog4,
.prog5,
.prog6,
.prog7,
.prog8,
.prog9,
.prog10,
.prog11,
.prog12 {
  border-radius: var(--br-xl);
  background-color: #fff;
  width: 25px;
  height: 25px;
  transform: rotate(-90deg);
}

.divprog-bar1,
.divprog-bar2,
.divprog-bar3,
.divprog-bar4,
.divprog-bar5,
.divprog-bar6,
.divprog-bar7,
.divprog-bar8,
.divprog-bar9,
.divprog-bar10,
.divprog-bar11,
.divprog-bar12 {
  height: 25px;
  border-radius: 0 10px 10px 0;
  width: 288px;
  background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.divprog-bar {
  height: 25px;
  border-radius: 0 10px 10px 0;
  width: 288px;
  background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
  margin-left:37px;
}

.progress-moved .divprog-bar1 {
  width: var(--changeMS1);
  background-color: #3496ce;
  animation: progressAnimation7 6s;
}

@keyframes progressAnimation7 {
  0% {
    width: 0%;
    background-color: #ffff;
  }

  100% {
    width: var(--changeMS1);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar2 {
  width: var(--changeMS2);
  background-color: #3496ce;
  animation: progressAnimation8 6s;
}

@keyframes progressAnimation8 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS2);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar3 {
  width: var(--changeMS3);
  background-color: #3496ce;
  animation: progressAnimation9 6s;
}

@keyframes progressAnimation9 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS3);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar4 {
  width: var(--changeMS4);
  background-color: #3496ce;
  animation: progressAnimation10 6s;
}

@keyframes progressAnimation10 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS4);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar5 {
  width: var(--changeMS5);
  background-color: #3496ce;
  animation: progressAnimation11 6s;
}

@keyframes progressAnimation11 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS5);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar6 {
  width: var(--changeMS6);
  background-color: #3496ce;
  animation: progressAnimation12 6s;
}

@keyframes progressAnimation12 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS6);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar7 {
  width: var(--changeMS7);
  background-color: #3496ce;
  animation: progressAnimation13 6s;
}

@keyframes progressAnimation13 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS7);
    background-color: #3496ce;
  }
}


.progress-moved .divprog-bar8 {
  width: var(--changeMS8);
  background-color: #3496ce;
  animation: progressAnimation14 6s;
}

@keyframes progressAnimation14 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS8);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar9 {
  width: var(--changeMS9);
  background-color: #3496ce;
  animation: progressAnimation15 6s;
}

@keyframes progressAnimation15 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS9);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar10 {
  width: var(--changeMS10);
  background-color: #3496ce;
  animation: progressAnimation16 6s;
}

@keyframes progressAnimation16 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS10);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar11 {
  width: var(--changeMS11);
  background-color: #3496ce;
  animation: progressAnimation17 6s;
}

@keyframes progressAnimation17 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS11);
    background-color: #3496ce;
  }
}

.progress-moved .divprog-bar12 {
  width: var(--changeMS12);
  background-color: #3496ce;
  animation: progressAnimation18 6s;
}

@keyframes progressAnimation18 {
  0% {
    width: 0%;
    background-color: #fff;
  }

  100% {
    width: var(--changeMS12);
    background-color: #3496ce;
  }
}


.bar-row {
  width:9%;
}

.vertical-number {
  width: 100%;
}

.max-speed-panel {
  top:610px!important;
}
.digitsBlock .div24, .digitsBlock .hours3 {
  display: block;
  height: auto;
  line-height: auto !important;
}
.p-screen-panel.advanced-dashboard-wrapper .p-logo .innerLogo {
  background: #1a2e46;
}
.advanced-dashboard-wrapper .backLink, .advanced-dashboard-wrapper .backLink:hover {
  color: #ffffff!important;
  text-decoration: none !important;
}
.maxspeed {
  padding: 16px;
  margin: 20px 30px
   0 0 !important;
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100) !important;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--color-darkslategray-100);

}

.maxspeed-avgditance {
  text-align: right;
}

.maxspeed-total-fleet {
  text-align: right;
}

.maxspeed-data {
  /*background: url("../assets/images/Vertical-Lines2.png") bottom left;*/
  background-repeat: no-repeat;
  display: flex;
  padding: 0 0 0 10px;
}


.workingandreminder {
  display: flex;
}



.working-hours {
  height: 260px !important;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
  border-radius: var(--br-7xs);
  background-color: var(--color-darkslateblue-300);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  overflow: hidden;
  padding: 20px;
  margin-top: 20px;
  margin: 20px 10px 0 0px;
}
.working-hours .ant-card-head {

  margin: 0 0 12px 0;
}
.working-hours .ant-card-head .ant-card-head-title {
  font-size: var(--font-size-lg);
  color: white;
  font-weight: normal;
  text-align:left;
}

.total-hours {
  background-color: white;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: absolute;
  margin: 0px 25%;
}

.total-hours p {
  padding: 25%;
  font-weight: 500;
}

.night-hours {
  background-color: #CAF5FF;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  margin: 0px 55%;
}

.night-hours p {
  padding: 25%;
  font-weight: 500;
}

.day-hours {
  background-color: #FFFAD0;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  margin: 0px 10%;
}

.day-hours p {
  padding: 20%;
  font-weight: 500;
}

.allcircles {
  position: relative;
}





/* Maintenance Reminder CSS */
.maintanance-reminder {
  font-size: var(--font-size-18xl);
  color: var(--color-gray-300);
  border-radius: var(--br-7xs);
  background-color: var(--color-mediumvioletred-200);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  overflow: hidden;
  margin: 20px 20px 0 8px;
  padding: 10px 20px;
}

.maintanance-head {

  margin: 0 0 12px 0;
  font-size: var(--font-size-mid);
  color: white;
  font-weight: normal;
  line-height: 20px;
}

.maintain-due {
  background-color: white;
  border-radius: 6px;
  height: 75px;
  width: 100%;
  margin: 25px auto;
  padding: 10px;
}

.calen {
  background-color: #CE9500;
  height: 50px;
  width: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin-top: -20px;
}

.maintanance-reminder img, .renew-reminder img {
  height: 50px;
  width: 35px;
  margin: 0px 8px;
  vertical-align: top;
}

.maintain-due p {
  font-weight: 500;
  font-size: 14px;
  float: right;
  margin: -10px 0 0 0;
}

.maintain-due span {
  font-weight: 600;
  font-size: 32px;
}

.alarm {
  background-color: #BD0909;
  height: 50px;
  width: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin-top: -20px;
}

.alarm img {
  height: 35px;
  width: 35px;
  margin: 0px 8px;
}
.maintain-due p {
  font-weight: 500;
  font-size: 14px;
  float: right;
  margin: -10px 0 0 0;
}


/* Renewal Reminder CSS */

.renew-reminder {
  background-color: var(--color-purple);
  font-size: var(--font-size-18xl);
  color: var(--color-gray-300);
  border-radius: var(--br-7xs);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  overflow: hidden;
  margin-top: 20px;
  padding:10px 20px;
}


/* Distance Classification CSS */
.distanceandscore {
  display: flex;
}

.distance-classification {
  height: 300px;
  text-align: ;
  font-size: var(--fon centert-size-base);
  color: var(--color-gray-200);
  border-radius: var(--br-7xs);
  background-color: var(--color-saddlebrown);
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  overflow: hidden;
  padding: 20px;
  margin-top: 20px;
  margin: 20px 10px 0 0px !important;
}
.distance-classification .ant-card-head {
  margin: 0 0 20px 0;
}
.meter {
  display: flex;
}

.distance-leftcontent {
  width: 50%;
}

.distance-rightcontent {
  width: 50%;
  padding: 70px 0 0 0;
}

.on-duty {
  margin: 10px 0px;
  background-color: var(--color-goldenrod-100);
  height: 50px;
  border-radius: 6px;
  padding: 6px 10px 10px 10px;
    line-height: 18px;
}

.on-duty p {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: white;
}

.on-duty span {
  font-weight: 600;
}

.off-duty {
  margin: 10px 0px;
  background-color: var(--color-firebrick);
  height: 50px;
  border-radius: 6px;
  padding: 6px 10px 10px 10px;
    line-height: 18px;
}

.off-duty p {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: white;
}

.off-duty span {
  font-weight: 600;
}

.total-duty {
  margin: 10px 0px;
  background-color: var(--color-darkslategray-200);
  height: 25%;
  border-radius: 6px;
  padding: 6px 10px 10px 10px;
  line-height: 18px;
}

.total-duty p {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: white;
}

.total-duty span {
  font-weight: 600;
}

.meter-1-icon {
  width: 181px;
  height: 203px;
  object-fit: cover;
}

.driver-score {
  height: 300px !important;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
  border-radius: var(--br-7xs);
  background-color: white;
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.06) !important;
  border: 1px solid var(--color-gainsboro-100);
  overflow: hidden;
  padding: 20px;
  margin:20px 0px 0 10px !important;
}
.driver-score .ant-card-head {
  margin: 0 0 20px 0;
}
.driver-score .ant-card-head-title {
  text-align: left;
  font-weight: 600;
  font-size: var(--font-size-lg);
  color: var(--color-gray-400)
}


.vehicle-score-graph-captions {
  position: absolute;
  top: 15%;
  right:10%;
}

.vehicle-score-graph-captions ul{
	list-style:none;
	margin:0;
	padding:0;
	text-align:left;
	font-size:13px;
}

.vehicle-score-graph-captions ul li{
	display:flex;
	align-items:Center;
	margin:0 0 5px 0;
	color:#3b3f5c;
	font-size:13px;
	font-weight:normal;
}

.vehicle-score-graph-captions ul li span{
	height:10px;
	width:10px;
	margin-right:10px;
	border-radius:3px;
}
.vehicle-score-graph-captions ul li.account span {
	background-color:#66c2a5;
}

.vehicle-score-graph-captions ul li.breakcount span {
	background-color:#fc8d62;
}

.vehicle-score-graph-captions ul li.exidelcount span {
	background-color:#8da0cb;
}

.vehicle-score-graph-captions ul li.rpmcount span {
	background-color:#e78ac3;
}

.vehicle-score-graph-captions ul li.speedcount span {
	background-color:#a6d854;
}
/* .ant-card .ant-card-head::before {
  background: url("../assets/images/opt9.png") center center !important;
  position: absolute;
  width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
} */

.ant-card.close-window .ant-card-head::before {
  background: url("../assets/images/opt9.png") center center !important;
  position: absolute;
  width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
}
.working-hours .ant-card-head::before, .maintanance-reminder .ant-card-head::before, .renew-reminder .ant-card-head::before {
  background: url("../assets/images/opt10.png") center center;
}
.working-hours b {
  display: block;
}
.working-hours p {
  line-height: 20px;
}

.arabic-language-wrapper .maintain-due p {
  float: left;
}

.arabic-language-wrapper.advanced-dashboard-wrapper .vehicle-score-graph-captions {
  right:4%;
  top:20%;
}
.arabic-language-wrapper.advanced-dashboard-wrapper .vehicle-score-graph-captions ul li span {
  margin: 0 0 0 10px;
}

.arabic-language-wrapper.advanced-dashboard-wrapper .jobinfo-icons {
 margin: 10px 0 0 10px;
}

.arabic-language-wrapper .fleet-idle {
  margin: 0 0 0 10px !important;
}
.arabic-language-wrapper .dash .leftPanel {
  padding: 0 0 0 30px !important;
}
.arabic-language-wrapper.vehicle-score-graph-captions {
  right: 2%;
  top: 20%;
}
.arabic-language-wrapper .vehicle-score-graph-captions ul li span {
  margin: 0 0 0 10px;
}
.arabic-language-wrapper .distance-classification {
  margin: 20px 0 0 10px !important;
}

.arabic-language-wrapper .working-hours {
  right: 0 !important;
}

.arabic-language-wrapper .maintanance-reminder {
  right: 0 !important;
}

.arabic-language-wrapper .bars {
  left: auto;
  right: 5px;
}

.arabic-language-wrapper.advanced-dashboard-wrapper .maxspeed {
  direction: ltr !important;
}

.arabic-language-wrapper .ant-card .ant-card-head::before {
  right: auto;
  left: 15px;
}
.arabic-language-wrapper .job-info {
  margin: 0 10px 0 0;
}
.arabic-language-wrapper .maxspeed {
  margin: 20px 0px 0 30px !important;
}
.arabic-language-wrapper .maintanance-reminder {
  margin: 20px 10px 0 8px;
}
.arabic-language-wrapper .working-hours {
  margin: 20px 0 0 10px;
}
.arabic-language-wrapper .driver-score {
  margin:20px 10px 0 0px !important
}
.arabic-language-wrapper .driver-score .ant-card-head-title {
  text-align: right;
}

.arabic-language-wrapper .vehicle-score-graph-captions {
  top: 23%;
  right: 5%;
}
.arabic-language-wrapper .jobinfo-icons {
  margin: 10px 0 0 5px;
}
.arabic-language-wrapper .maxspeed {
  direction: ltr !important;
}
.arabic-language-wrapper .maxspeed .ant-card-head, .arabic-language-wrapper .working-hours .ant-card-head .ant-card-head-title {
  text-align: right;
}

.bar-row strong.vehicleNum-5 {
  margin: 15px 8px 0 -15px;
 }
 .bar-row strong.vehicleNum-8 {
  margin: 17px 8px 0 -11px;
 }
 .bar-row strong.vehicleNum-15 {
  margin: 45px 8px 0 -38px;
 }
 .bar-row strong.vehicleNum-20 {
  margin: 55px 8px 0 -41px;
 }
 .digitsBlock .div23, .digitsBlock .div24 {
  font-size: 32px;
 }