
.addon label{
    margin: 10px 0px;
    width: 100%;
}

.ant-tabs-nav .ant-tabs-nav-wrap{
  overflow: visible !important;
}
.ant-tabs .ant-tabs-nav{
   /* background-color: #203254; */
    /* border-radius: 10px 10px 0 0; */
    /*margin-bottom: 20px;*/
    /* width: 100%; */
}
.ant-tabs-tab{
padding: 0px !important;
margin: 10px 10px;
}
.ant-tabs-tab-active{
    background-color: #fff !important;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-active span{
    color: #0090E7 !important;
    font-size: 16px;
    font-weight: 500;
}
.ant-tabs-tab span{
    font-size: 16px;
    font-weight: 500;
    /* color: #fff; */
}
.ant-tabs-ink-bar{
    display: none;
}
.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin: 10px 10px 10px 10px; 
}
.validation-failed{
    padding: 5px 0px !important;
    background-color: #fff !important;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgba(255, 0, 0, 0.25);
}

.validation-failed span{
    font-size: 16px;
    font-weight: 500;
    color: #ff0000 !important;
}

.formbuttons{
    position:absolute;
    left: 45%;
}

/* .ant-tabs {border: 1px solid #000000;
border-radius: 10px;
margin: 10px 70px 0px 70px;
padding-bottom: 10px;
min-height: 750px;} */

/* .formbuttons button{
    height: 45px;
    width: 130px;
    border: none !important;
} */

.backbtn{
    background-color: #e6e6e6;
    /* font-size: 18px; */
}

.nextbtn{
    background-color: #1677ff;
    color: #fff;
    /* font-size: 18px; */
}
.ant-switch.ant-switch-checked{
    background-color: #203254 !important;
}
.imgupload{
    background-color: #203254 !important ;
    color: #fff;
}

.mapcheck{
    margin: 0px !important;
    max-height: 100px;
}

/* Vehicle Form */
.fieldsrow{
    margin-bottom: 0px !important;
}




.form{
    width: 500px;
    margin: 0 auto;
    margin-top: 150px;
    font-family: sans-serif;
    background: #fff
  }
  .form-item{
    position: relative;
    margin-bottom: 15px
  }
  .form-item input{
    display: block;
    width: 400px;
    height: 40px;
    background: transparent;
    border: solid 1px #ccc;
    transition: all .3s ease;
    padding: 0 15px
  }
  .form-item input:focus{
    border-color: blue
  }
  .form-item label{
    position: absolute;
    cursor: text;
    z-index: 2;
    top: 13px;
    left: 10px;
    font-size: 12px;
    font-weight: bold;
    background: #fff;
    padding: 0 10px;
    color: #999;
    transition: all .3s ease
  }
  .form-item input:focus + label,
  .form-item input:valid + label{
    font-size: 11px;
    top: -5px
  }
  .form-item input:focus + label{
    color: blue
  }
  .extField {
    margin-inline-end: 13px;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
 
    margin-block-start:10px;
    margin-block-end:10px;
    margin-inline-start:10px;
    margin-inline-end:10px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    margin-inline-end:32px;
    }

  .ant-tabs-nav-list {

    align-items: center;
  }
  .ant-tabs-nav .ant-tabs-tab-btn .ant-btn {
    height: 40px;
  }