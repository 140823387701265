* {
  font-family: 'Poppins', sans-serif;
}
html {
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.ant-table th.ant-table-selection-column {
  width: 300px;
}
.welcome-block {
  position: relative;
}
.welcome-block .welcomeInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.welcome-block .welcomeInfo p {
  color: #ffffff;
  font-size: 28px;
  font-weight: 400;
  margin: 0 0 10px 0;
}
.welcome-block .welcomeInfo h1 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 64px;
  font-weight: 800;
  position: relative;
}
.welcome-block .welcomeInfo h1:before {
  position: absolute;
  background: #ffffff;
  height: 4px;
  width: 80px;
  border-radius: 30px;
  content:'';
  left: 0;
  right: 0;
  bottom:-5px;
  margin: 0 auto;
}
.login-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.login-block h2 {
  color: #1A3665;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin: 20px 0;
}
.login-block .login-logo {
  width: 80%;
  margin: 0 auto;
}
.login-block .login-form {
  width: 70%;
  margin: 0 auto;
}
.login-block .login-form .ant-form-item {
  margin: 10px 0 10px 0;
}
.login-block .login-form .ant-form-item-label {
  bottom: auto;
  padding: 0;
  color: #1A3665 !important;
  font-size: 16px;
  font-weight: 600;
  inset-inline-start: 0;
  margin: 0 0 5px 0;
}
.login-block .login-form .ant-form-item-label >label {
  color: #1A3665 !important;
  font-size: 16px;
  font-weight: 600;
}
.login-block .login-form .ant-form-item-label label {
  padding:0;
}
.login-block .login-form .ant-input, .login-block .login-form .ant-input-password {
  background: rgba(26, 54, 101, 0.1);
  border-radius: 5px;
  border: 0;
  position: relative;
  border-left: solid 5px #1A3665;
  height: 50px;
  color: #262626;
  font-size: 14px;
}
.login-block .login-form .ant-input:focus, .login-block .login-form .ant-input-password .ant-input:focus {
  box-shadow: none;
}
.login-block .login-form .ant-input-password .ant-input {
  border: 0;
  background:transparent;
}
.login-block .login-form  .ant-btn.loginBtn {
  height: 50px;
  border-radius: 5px;
  background: #1A3665;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin:0;
}
.login-block .login-form .loginCheckbox {
  margin: 20px 0;
}

.login-block .login-form .ant-checkbox-wrapper span {
  font-size: 14px;
  font-weight: 300;
  color: #1A3665;

}

/*.login-block .login-form  .ant-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background-color: ffffff;
  border-color:#1A3665;
}

.login-block .login-form  .ant-checkbox-disabled .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background-color:#1A3665;
  border-color: #1A3665;
}

.login-block .login-form  .ant-checkbox-checked .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background-color: #1A3665;
  border-color: #1A3665;
}*/


.login-block .login-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1A3665 !important;
  border-color: #1A3665;
}
.login-block .login-form .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1A3665 !important;
  border-color: #1A3665 !important;
}
.login-block .login-form .ant-checkbox-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1A3665 !important;
  border-color: #1A3665 !important;
}
.login-block .login-form .ant-checkbox-checked:after {
  border-color: #ffffff !important;
}
.login-block .login-form .ant-checkbox:hover::after {
  border-color: #1A3665 !important;
}
.login-block .login-form .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1A3665 !important;
}

.login-block .login-form .ant-switch.ant-switch-checked{
  background-color: #1A3665 !important;
}
.login-block .login-form .ant-switch.ant-switch {
  background-color: #256496 !important;
  height:30px;
  line-height: 30px;
}
.login-block .login-form .ant-switch.ant-switch-checked{
  background-color: #1A3665 !important;
}
.login-block .login-form .ant-switch .ant-switch-handle::before {
  top: 4px;
  width: 17px;
  height: 17px;
}
.login-block .login-form .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -31px;
}

.login-block .login-form .ant-form-vertical .ant-form-item-label >label {
  background: transparent;
}
.login-block .login-form .ant-form-item .ant-form-item-control {
  height: auto;
}
.login-block .login-form .loginLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-block .login-form .loginLinks  a {
  font-size: 14px;
  font-weight: 300;
  color: #1A3665;
}