body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-lg: 24px;
  --font-size-xs: 12px;
  --font-size-smi: 13px;
  --font-size-base: 16px;
  --font-size-18xl: 37px;
  --font-size-4xs: 9px;
  --font-size-9xl: 28px;
  --font-size-2xs: 11px;
  --font-size-mid: 17px;

  /* Colors */
  --color-gainsboro-100: #e0e6ed;
  --color-gainsboro-200: #e0e0e0;
  --color-white: #fff;
  --color-gray-500: #939393;
  --color-gray-600: #8b7e74;
  --color-gray-100: #747a80;
  --color-gray-200: #2a2a2a;
  --color-gray-300: #282828;
  --color-gray-700: #232323;
  --color-gray-400: #0e1726;
  --color-gray-800: rgba(0, 0, 0, 0.8);
  --color-whitesmoke-100: #ebedf2;
  --color-darkcyan: #159895;
  --color-lightslategray-100: #888ea8;
  --color-lightslategray-200: #6096b4;
  --color-darkslategray-100: #3b3f5c;
  --color-darkslategray-200: #404040;
  --color-darkslategray-300: #363639;
  --color-darkslategray-400: #333235;
  --color-green-200: #377d01;
  --color-green-100: #027200;
  --color-darkred: #981515;
  --color-olive: #988315;
  --color-salmon: #ff6f6f;
  --color-darkslateblue-200: #7f669d;
  --color-darkslateblue-100: #1c4093;
  --color-darkslateblue-300: #002b5b;
  --color-slategray: #7d6e83;
  --color-sienna: #a97155;
  --color-steelblue: #6b7aa1;
  --color-darkolivegreen: #868b62;
  --color-goldenrod-200: #e3a507;
  --color-goldenrod-100: #ce9500;
  --color-firebrick: #bd0909;
  --color-mediumvioletred-100: #e3079d;
  --color-mediumvioletred-200: #7d0a41;
  --color-purple: #5b003c;
  --color-black: #000;
  --color-saddlebrown: rgba(109, 78, 0, 0.1);
  --color-darkgray: #9f9f9f;
  --color-blueviolet: #b844ff;
  --color-lightseagreen: #00c2b2;
  --color-limegreen: #33ac09;

  /* border radiuses */
  --br-7xs: 6px;
  --br-xl: 20px;
  --br-base: 16px;
  --br-6xs: 7px;
  --br-2xs: 11px;
  --br-8xs: 5px;
}
