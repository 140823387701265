
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.theme-login-wrapper {
	/* background:url(assets/images/drive7/loginBg.png) no-repeat top center fixed; */
	background-position: center;
	background-size:100% 100%;
	margin:0 auto;
	padding:0 50px;
	font-family: "Outfit", sans-serif;
	font-size:14px;
	width: 100%;
	height: 100%;
}
.theme-login-wrapper * {
    font-family: "Outfit", sans-serif;
}
h1, h2, h3, h4, h5, p {
	margin:0;
	padding:0;
}
.theme-login-wrapper .login-container  {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width:500px;
}
.theme-login-wrapper .login-container .login-logo {
	text-align:center;
	margin:0 0 20px 0;
}
.theme-login-wrapper .login-container .login-logo img {
	width:80%;
}
.theme-login-wrapper .login-container .login-form {
	border-radius:15px;
	padding:30px 40px 30px 40px;
}
.theme-login-wrapper .login-container .login-form p {
	opacity: 0.7;
	font-size:17px;
	font-weight:normal;
	margin:0 0 40px 0;
}
.theme-login-wrapper .login-container .login-form .formfield {
	margin:0 0 10px 0;
	position:relative;
}
.theme-login-wrapper .login-container .login-form .formfield label {
	display:block;
	margin:0 0 8px 0;
	font-size:18px;
	font-weight:500;
}
.theme-login-wrapper .username .ant-form-item-control, .theme-login-wrapper .password .ant-form-item-control {
	border-radius: 5px;
}
.theme-login-wrapper .username .ant-form-item-control .ant-form-item-control-input, .theme-login-wrapper .password .ant-form-item-control .ant-form-item-control-input {
	border: 0;
	border-radius: 5px;
}
.theme-login-wrapper .password  .ant-input-affix-wrapper {
	border: 0;
}
.theme-login-wrapper .login-container .login-form .formfield input, .theme-login-wrapper .login-container .login-form .formfield select {
	border:0;
	padding:0 15px;
	font-size:16px;
	font-family: "Outfit", sans-serif;
	box-sizing: border-box;
	width:100%;
    font-size: 14px;
    height: 50px;
    position: relative;
	font-weight:300;
	box-shadow:none;
	outline:0;
	border-radius: 0 5px 5px 0;
}
.theme-login-wrapper .login-container .login-form .formfield input:focus, .theme-login-wrapper .login-container .login-form .formfield select:focus
 {
outline: 0;
box-shadow: none;
}
.theme-login-wrapper .login-container .login-form .formfield.password input {
	border-radius: 0;
}
.theme-login-wrapper .login-container .login-form .formfield select {
	background:#ffffff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
	-webkit-appearance: none;
	width:100%;
}

.login-form::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color:rgba(0, 0, 0, 0.7);
	font-family: "Outfit", sans-serif;
}
.login-form::-moz-placeholder { /* Firefox 19+ */
	color:rgba(0, 0, 0, 0.7);
	font-family: "Outfit", sans-serif;
}
.login-form:-ms-input-placeholder { /* IE 10+ */
	color:rgba(0, 0, 0, 0.7);
	font-family: "Outfit", sans-serif;
}
.login-form:-moz-placeholder, .login-form:-moz-placeholder { /* Firefox 18- */
	color:rgba(0, 0, 0, 0.7);
	font-family: "Outfit", sans-serif;
}
.theme-login-wrapper .login-container .login-form .formfield button {
	margin:25px 0 0 0;
	width:100%;
	border:solid 1px transparent;
	border-radius:30px;
	height:50px;
	font-size:16px;
	text-transform:none;
	font-weight:600;
	font-family: "Outfit", sans-serif;
 -webkit-transition: all .3s linear;
 -moz-transition: all .3s linear;
 -o-transition: all .3s linear;
 transition: all .3s linear;
}
.theme-login-wrapper .login-container .login-form .formfield button:hover {

}
.theme-login-wrapper .login-container .link-field {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.theme-login-wrapper .login-container .link-field .forgot-password a {
	font-size:15px;
	font-weight:400;
	text-decoration:none;
}
.theme-login-wrapper .login-container .link-field .forgot-password a:hover {
	text-decoration:underline;
}
.theme-login-wrapper .login-container .link-field .remember .container-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align:middle;
}
.theme-login-wrapper .login-container .link-field .remember .container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
/* Create a custom checkbox */
.theme-login-wrapper .login-container .link-field .remember .container-checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width:22px;
	border:solid 1px rgba(0, 0, 0, 0.35);
	border-radius:4px;
}

/* On mouse-over, add a grey background color */
.theme-login-wrapper .login-container .link-field .remember .container-checkbox:hover input ~ .checkmark {
	border:solid 1px rgba(0, 0, 0, 0.35);
}


/* Create the checkmark/indicator (hidden when not checked) */
.theme-login-wrapper .login-container .link-field .remember .container-checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.theme-login-wrapper .login-container .link-field .remember .container-checkbox input:checked ~ .checkmark:after {
 display: block;
}
/* Style the checkmark/indicator */
.theme-login-wrapper .login-container .link-field .remember .container-checkbox .checkmark:after {
	left: 7px;
    top:3px;
    width: 6px;
    height:9px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}



.theme-login-wrapper .login-container .formfield button, .theme-login-wrapper .login-container .formfield input[type=submit], .theme-login-wrapper .login-container .formfield input[type=button] {
	margin:25px 0 0 0;
	width:100%;
	border-radius:5px;
	height:50px;
	font-size:18px;
	text-transform:none;
	font-weight:600;
	font-family: "Outfit", sans-serif;
	-webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
.theme-login-wrapper .login-container .welcomeInfo {
	text-align:center;
	margin:0 0 30px 0;
}
.theme-login-wrapper .login-container .welcomeInfo p {
    font-size: 28px;
    font-weight: 400;
    margin: 0 0 10px;
}
.theme-login-wrapper .login-container .welcomeInfo h1 {
    font-size:44px;
    font-weight: 800;
    position: relative;
    text-transform: uppercase;
}
.theme-login-wrapper .login-container .welcomeInfo h1:before {
    border-radius: 30px;
    bottom: -5px;
    content: "";
    height: 4px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80px;
}

.theme-login-wrapper .login-container .login-form .formfield.language input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden ;
}

.theme-login-wrapper .login-container .login-form .formfield.language label {
	cursor: pointer;
	width:80px;
	height: 33px;
	display: flex;
    justify-content: flex-start;
    align-items: center;
	border-radius: 75px;
	position: relative;
	padding:0 0 0 15px;
	font-size:14px;
	font-weight:400;
}

.theme-login-wrapper .login-container .login-form .formfield.language label:after {
	content: '';
	position: absolute;
	top: 6px;
	right: 5px;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	transition: 0.3s;
}
.theme-login-wrapper .login-container .toggle-password {
	position:absolute;
	right:15px;
	top:46px;
	font-size:22px;
}

.theme-login-wrapper .loginCheckbox {
	margin: 20px 0;
  }
  
  .theme-login-wrapper .ant-checkbox-wrapper span {
	font-size: 15px;
	font-weight: 400;
  }
  
  .theme-login-wrapper .ant-switch.ant-switch {
	height:33px;
	line-height: 33px;
	width: 95px;
  }

  .theme-login-wrapper .ant-switch .ant-switch-handle::before {
	top: 6px;
	width: 17px;
	height: 17px;
	inset-inline-start: -3px;
  }
  .theme-login-wrapper .ant-switch .ant-switch-inner .ant-switch-inner-checked {
	font-size: 14px;
	font-weight: 400;
	font-family: "Outfit", sans-serif;
	line-height: 28px;
  }
.theme-login-wrapper .ant-switch .ant-switch-inner {
  padding-inline-start: 10px;
  padding-inline-end: 29px;

}
  .theme-login-wrapper .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
	margin-top: -31px;
  }
.theme-login-wrapper .languageBlock {
	text-align: start;
	margin: 0 0 10px 0;
}
.theme-login-wrapper .ant-form-vertical .ant-form-item-label {
	bottom: -10px;
	inset-inline-start: 0;
}
.theme-login-wrapper .ant-form-vertical .ant-form-item-label label {
	background: transparent;
	display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 500
}
.theme-login-wrapper .ant-input-password .ant-input-suffix {
	margin-inline-start: 15px;
}
.theme-login-wrapper .login-container .login-form .formfield .ant-input-password input {
	width: 90%;
	outline: 0;
	box-shadow: none !important;
}
.theme-login-wrapper .login-container .login-form .loginLinks {
	display: flex;
    justify-content: space-between;
	align-items: center;
	margin: 0 0 5px 0;
}
.theme-login-wrapper .ant-checkbox-inner {
	width: 22px;
	height: 22px;
}
.theme-login-wrapper .forgotLink {
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}
.theme-login-wrapper .forgotLink:hover {
	text-decoration:underline;
}
.theme-login-wrapper  .loginBtn {
    border-radius: 5px;
    height: 50px;
    font-size: 18px;
    text-transform: none;
    font-weight: 600;
    font-family: "Outfit", sans-serif;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
	margin: 15px 0 0 0;
}
.theme-login-wrapper video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
   }
   .theme-login-wrapper .loginLinks .ant-checkbox .ant-checkbox-inner:after {
	inset-inline-start: 25.5%;
   }
   .theme-login-wrapper .ant-form-item-control {
	height: auto !important;
   }

   .theme-login-wrapper  .ant-input-affix-wrapper:focus {
		outline: 0;
		box-shadow: none;
   }


/* theme colors */

.theme-login-wrapper .login-container .login-form {
	background:rgba(255, 255, 255, 0.95);
}
.theme-login-wrapper .login-container .welcomeInfo p {
    color: #ffffff;
}
.theme-login-wrapper .login-container .welcomeInfo h1 {
    color: #ffffff;
}
.theme-login-wrapper .login-container .welcomeInfo h1:before {
    background: #ffffff;
}
.theme-login-wrapper .login-container .formfield button, .theme-login-wrapper .login-container .formfield input[type=submit], .theme-login-wrapper .login-container .formfield input[type=button] {
	border:solid 1px #1a3665;
	background:#1a3665;
	color:#ffffff;
}
.theme-login-wrapper .login-container .formfield button:hover, .theme-login-wrapper .login-container .formfield input[type=submit]:hover, .theme-login-wrapper .login-container .formfield input[type=submit]:focus, .theme-login-wrapper .login-container .formfield input[type=button]:hover, .theme-login-wrapper .login-container .formfield input[type=button]:focus {
	background:#234379;
}
.theme-login-wrapper .login-container .link-field .remember .container-checkbox input:checked ~ .checkmark, .theme-login-wrapper .login-container .link-field .remember .container-checkbox input:checked ~ .checkmark:hover {
	background-color: #1a3665;
	border:solid 1px #1a3665;
}
.theme-login-wrapper .login-container .link-field .forgot-password a {
	color:#1a3665;
}
.theme-login-wrapper .login-container .login-form .formfield.language label {
	background:#1a3665;
	color:#ffffff;
}

.theme-login-wrapper .login-container .login-form .formfield.language label:after {
	background: #ffffff;
}

.theme-login-wrapper .login-container .login-form p {
	color:#13213B;
}
.theme-login-wrapper .login-container .login-form .formfield label {
	color:#1a3665;
}
.theme-login-wrapper .login-container .login-form .formfield input, .theme-login-wrapper .login-container .login-form .formfield select {
	background:#e8ebef;
    color: #262626;
}

.theme-login-wrapper .login-container .login-form .formfield button {
	background:#1B3127;
	border:solid 1px #1B3127;
	color:#ffffff;
}
.theme-login-wrapper .login-container .login-form .formfield button:hover {
	background:#2e4c3e;
}
.theme-login-wrapper .login-container .link-field .remember .container-checkbox {
	color: #4B4B4B;
}

.theme-login-wrapper .login-container .link-field .remember .container-checkbox .checkmark {
	background-color: #ffffff;
}
.theme-login-wrapper .login-container .toggle-password {
	color: #595959;
}
.theme-login-wrapper .login-container .link-field .remember .container-checkbox:hover input ~ .checkmark {
	background-color: #ffffff;
}


.theme-login-wrapper .ant-checkbox-wrapper span {
	color: #1A3665;
  
  }
  .theme-login-wrapper .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1A3665 !important;
	border-color: #1A3665;
  }
  .theme-login-wrapper .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1A3665 !important;
	border-color: #1A3665 !important;
  }
  .theme-login-wrapper .ant-checkbox-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1A3665 !important;
	border-color: #1A3665 !important;
  }
  .theme-login-wrapper .ant-checkbox-checked:after {
	border-color: #ffffff !important;
  }
  .theme-login-wrapper .ant-checkbox:hover::after {
	border-color: #1A3665 !important;
  }
  .theme-login-wrapper .ant-checkbox:hover .ant-checkbox-inner {
	border-color: #1A3665 !important;
  }

.theme-login-wrapper .ant-switch.ant-switch-checked{
	background-color: #1A3665 !important;
  }
  .theme-login-wrapper .ant-switch.ant-switch {
	background-color: #256496 !important;
  }
  .theme-login-wrapper .ant-switch.ant-switch-checked{
	background-color: #1A3665 !important;
  }
  .theme-login-wrapper .ant-form-vertical .ant-form-item-label label {
		color: #1a3665;
  }
  .theme-login-wrapper .forgotLink {
	color: #1a3665;
  }
  .theme-login-wrapper  .loginBtn {
	border: solid 1px #1a3665;
    background: #1a3665;
  }
  .theme-login-wrapper  .loginBtn:hover {
	background: #234379;
	border: solid 1px #234379;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
	 transition: background-color 5000s ease-in-out 0s;
  }

  .theme-login-wrapper .username .ant-form-item-control .ant-form-item-control-input, .theme-login-wrapper .password .ant-form-item-control .ant-form-item-control-input {
	border-left: 5px solid #1a3665;
  }

  .theme-login-wrapper .ant-input-affix-wrapper {
		background: #e8ebef;
  }
  .theme-login-wrapper .ant-input-affix-wrapper:focus {
	outline: 0;
	box-shadow: none;
  }

/* theme box position */

.theme-login-wrapper .login-container.leftPosition {
    left: 20%;
}
.theme-login-wrapper .login-container.rightPosition {
    right:-5%;
	left:auto;
}
.theme-login-wrapper .login-container.centerPosition {
	left:50%;
}