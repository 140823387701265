.formBorder{
    border: 1px solid black;
    margin: 10px 70px;
    border-radius: 10px;
}

.formButtons{
    gap: 8px;
    display: flex;
    align-items: center;
    margin: 0px auto;
}

.headingTitle{
    background-color: #203254;
    line-height: 50px;
    margin: 0px;
    padding: 0 25px;
    color: #fff;
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    font-weight: 500;
}
.ant-layout{
    background: #fff;
    height: 100%;
}
.imgupload{
    background-color: #203254 !important ;
    color: #fff;
}