html body {
  overflow-y: visible !important;
  overflow-x: hidden !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-row-rtl .ant-form-item-explain-error {
  direction: rtl;
}

.heading {
  font-weight: bold;
}

.headerFixed {
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  background: #181722;
  color: white;
  padding: 0px 24px;
}

/* .ant-popover .ant-popover-inner {
    background-color: transparent;
    background-clip: padding-box;
    margin-left: 10px;
    padding: 0px;
} */

.ant-menu-dark {
  background: #181722 !important;
}

.ant-popover-placement-rightTop .ant-popover-arrow {
  display: none;
}
.personIcon {
  color: #bdbdbd;
  font-size: 28px;
}

.personIcon:hover {
  /* background-color: white; */
  color: white;
  /* font-size: 50px; */
}

.formShowModal .ant-modal .ant-modal-content {
  padding: 52px 24px !important;
}
/* .ant-modal .ant-modal-content{
  border-radius: 0px !important;
} */

/* change password form classes   */

.changePassForm label {
  color: white !important;
}

.changePassForm {
  background-color: #181722 !important;
  padding: 24px;
  width: 400px;

  border-radius: 10px;
}
.changePassForm .ant-input {
  background-color: #181722;

  color: white;
}

.changePassForm.ant-form-vertical .ant-form-item-label > label {
  background: #181722;
}
.changePassForm .ant-select .ant-select-arrow {
  color: white;
}
.changePassForm .ant-input-affix-wrapper > input.ant-input {
  border-radius: 8px;
  background: transparent;
  padding: 0 10px;
  color: #ffffff;
}
.changePassForm .ant-select.ant-select-in-form-item .ant-select-selector {
  border-radius: 8px;
  background: transparent;
  padding: 0 10px;
  color: #ffffff;
}
.changePassForm .ant-input-affix-wrapper {
  border-color: white !important;
  padding: 0px !important;
  background: transparent;
  /* border: none !important; */
}

.changePassForm .anticon.ant-input-password-icon {
  color: #ffffff;
  margin-inline-end: 10px;
}
.changePassForm
  .ant-input-affix-wrapper
  .anticon.ant-input-password-icon:hover {
  color: #ffffff;
}
.changePassForm .ant-form-item {
  margin: 0 0 10px 0;
}
.changePassForm .ant-form-item .ant-form-item-control {
  height: auto;
}

.changePassLayout {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25%;
  left: 41%;
}

.changePassHeadline {
  background-color: #181722;
  line-height: 50px;
  margin: 0px;
  padding: 0 25px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.6) 5px 5px 4px 0px;
  font-size: 16px;
  font-weight: 500;
}
.changePassBtn {
  width: 150px;
  height: 40px;
  border-color: white;
  color: white;
}

/* new form design */
.ant-btn-primary.ant-btn-background-ghost {
  margin: 0px 10px !important;
}

.formBtnGroup {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 16px 0 0 0;
  margin: 16px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.formSaveButton {
  width: 100px;
  height: 40px;

  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  margin: 0px 10px;
}

.formCancelButton {
  width: 100px;
  height: 40px;

  border-radius: 5px;
  background: #d9d9d9;
  color: #181722;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  margin: 0px 10px;
}
.formClass {
  max-width: 100%;
  padding-top: 10px;
}

.formColumnClass {
  padding-inline-end: 20px;
}

.formRowClass {
  display: flex;
}
/* .ant-modal .ant-modal-content{
padding: 0px;
} */

/* .ant-modal .ant-modal-header{
  padding: 17px
} */
/* .ant-modal-header
{
  margin-bottom: 0px !important;
} */

.overflowFormInputs {
  /*padding-top: 20px;*/
  overflow-y: scroll;
  height: 573px;
}

/* .ant-modal-root .ant-modal-centered .ant-modal{
  vertical-align: bottom !important;
} */

/* menu js  */

.ant-menu-submenu-popup
  .ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px !important;
  margin: 3px !important;
  background: #181722 !important;
  /* direction: rtl; */
}

/* .dropdown-menu.arabic-dropdown-menu {
  direction: rtl;
} */

/* .ant-popover .ant-popover-inner {
    background-color: transparent; 
    background-clip: padding-box;
    
    margin-left: 10px;
  
    padding: 0px;
} */

.ant-menu-dark {
  background: #181722 !important;
}

.ant-popover-placement-rightTop .ant-popover-arrow {
  display: none;
}
.personIcon {
  color: #bdbdbd;
  font-size: 28px;
}

.personIcon:hover {
  /* background-color: white; */
  color: white;
  /* font-size: 50px; */
}

.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}
.ant-tabs-nav .ant-tabs-tab-active {
  border-radius: 4px;
}
.ant-tabs-nav .ant-tabs-tab-btn .ant-btn {
  height: 36px;
}

/* Alert modal */

.ant-form-vertical .ant-form-item-label {
  position: relative;
  bottom: -15px;
  inset-inline-start: 12px;
  z-index: 99;
}

#form-password {
  height: 40px !important;
}
.ant-form-vertical .ant-form-item-label > label {
  background: white;
  font-size: 12px;
  padding: 0 2px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
}
.ant-input {
  height: 48px;
}
.ant-input.customInput {
  height: 40px;
}

.ant-picker .ant-picker-input > input {
  height: 38px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 50px;

}
.dashboard-panel .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 50px;
  inset-inline-start: 7px;
}
.listInputRow
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 38px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px;
}
.listInputRow
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 36px;
  font-weight: 300;
  font-size: 13px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  margin-top: 0;
}
.ant-form-item {
  margin: 0;
}

.ant-form-item.formCheckboxCss {
  margin: 3px 0 16px 0;
}
.ant-form-vertical .formCheckboxCss .ant-form-item-label {
  position: inherit;
  height: 50px;
}

.ant-form-vertical .vehicleFormCheckbox .ant-form-item-label {
  position: inherit;
  height: 20px;
}

.ant-form-item.disabledLabel .ant-form-item-label > label {
  background: whitesmoke;
  border-radius: 6px;
}
.ant-form-item .ant-form-item-control {
  height: 40px;
}
.ant-form-item .ant-form-item-explain-error {
  margin-top: 8px;
  font-size: 12px;
}

.alert-model .ant-modal-content {
  padding: 20px !important;
  border-radius: 10px !important;
  /* width: 350px; */
}
.alert-model .ant-modal-header {
  padding: 0px !important;
}

.addButton {
  border: 1px solid #fff;
}
.addButton .anticon svg {
  font-size: 23px !important;
}

/* Login Form Css */

.loginLanguageBtns {
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px 0;
}
.loginLanguageBtns .loginEngBtn,
.loginLanguageBtns .loginAraBtn {
  height: 40px;
  width: 46%;
  font-size: 16px;
  border-radius: 4px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #ffffff;
  box-shadow: none;
}
.loginLanguageBtns .activeBtn {
  background: #0090e7;
}
.loginLanguageBtns .ant-btn:hover,
.loginLanguageBtns .ant-btn:focus {
  background: #0090e7;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.loginForm .ant-form-item.loginFormItem {
  margin: 0 0 24px 0;
}
/* .loginForm .ant-form-item.loginFormItem .ant-form-item-control  { height:42px;} */
.loginForm .ant-form-item.loginFormItem .ant-input {
  height: 42px;
  /*color: #FFFFFF!important;*/
  border-radius: 4px;
}
.loginForm .ant-form-item.loginFormItem .ant-form-item-label {
  bottom: 0px;
  left: 0px;
}

.loginForm .ant-form-item.loginFormItem .ant-form-item-label > label {
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.loginForm .ant-form-item.loginFormItem .ant-input-affix-wrapper-lg {
  padding: 0;
  border-radius: 4px;
}

.loginForm .ant-form-vertical .ant-form-item-label {
  position: inherit;
}

.loginForm .ant-form-item.loginFormItem input:-internal-autofill-selected {
  background: transparent !important;
}
/* .loginCheckbox{} */
.loginForm .ant-checkbox-wrapper {
  font-size: 16px;
  color: #ffffff;
  margin: 0 0 16px 0;
}
.loginForm .ant-form-item .ant-form-item-control {
  height: auto;
}
.loginForm .login-form-forgot {
  text-align: center;
  margin: 16px 0 0 0;
  font-size: 16px;
  font-weight: 500;
}
.loginForm .login-form-forgot a {
  color: #ffffff;
  text-decoration: underline;
}

.loginForm .ant-btn.login-form-button {
  height: 48px;
  border-radius: 4px;
  background: #ffffff;
  color: #080710;
  font-weight: 700;
}

/* Default Buttons Css */

.forgetPassBtns {
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0 0;
}
.forgetPassBtns .commonBtns {
  min-width: auto;
  margin: 0;
  width: 48%;
}

.commonBtns {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  min-width: 140px;
  margin: 0 16px 0 0;
  border: 0px none;
}

.grayBtn {
  background: #d9d9d9;
  color: #181722;
}
.grayBtn:hover,
.grayBtn:focus {
  background: #d9d9d9;
  color: #181722;
}
.blueBtn {
  background: #0090e7;
  color: #ffffff;
}
.blueBtn:hover,
.blueBtn:focus {
  background: #0090e7;
  color: #ffffff;
}

.commonBtns.blueBtn.ant-btn-default:not(:disabled):hover {
  background: #0090e7;
  color: #ffffff;
}
.commonBtns.grayBtn.ant-btn-default:not(:disabled):hover {
  background: #d9d9d9;
  color: #181722;
}

/* List Input Row Css */

.listInputRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 16px 0;
}
/*.listInputRow .ant-space-item .ant-select-selection-search {
  margin: 4px 0 0 0;
}*/
.listInputRow .ant-space-item .ant-select-arrow {
  top: 12px;
  margin-top: 0;
}
.trackingRightPanel .listInputRow .ant-space-item .ant-select-arrow {
  top: 7px;
}
.listInputRow .ant-space-item .liveTrackingTimerCss .ant-select-arrow {
  top: 26px;
}
.listInputRow .ant-space-item .ant-select-selector {
  height: 36px;
}
.listInputRow .ant-space-item .ant-select-selection-placeholder {
  line-height: 36px;
}

.listInputRow .ant-input-search .ant-input {
  height: 26px;
}

.listInputRow .ant-input-search-button {
  height: 35px;
  border: 0px none;
}
.listInputRow .ant-picker .ant-picker-input > input {
  height: 26px;
}
.listInputRow .ant-input {
  height: 36px;
}
.listInputRow .ant-input-affix-wrapper .ant-input {
  height: 26px;
}
.listInputRow .ant-space-item .ant-btn {
  height: 36px;
  box-shadow: none;
}

/* .ant-popover .ant-popover-inner{background-color: none !important; 
      background-clip: none !important; 
      border-radius: none !important;
       box-shadow: none !important; 
     padding: none !important;
  } */
/*.ant-popover .ant-popover-inner {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
  }*/

.search-field .ant-select-selector {
  height: 45px !important;
}

/* Alert Form  */
.alert-form {
  max-height: 400px;

  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
}
.alert-form .header {
  margin: 10px 0 0 0;
}
.alert-form .paragraph {
  font-size: 13px;
  text-align: justify;
  max-width: 80%;
  margin-top: 20px;
}
.alert-form .disc {
  font-weight: bold;
}
.alert-form .altbtn {
  width: 35%;
}

.ant-form-vertical .label-seprator .ant-form-item-label {
  position: relative !important;
  bottom: -12px !important;
  left: 0px !important;
  /* /* z-index: 99; */
}
.ant-form-vertical .upload-field .ant-form-item-label {
  position: relative !important;
  bottom: 4px !important;
  left: 0px !important;
  /* /* z-index: 99; */
}

.geofenceReportTbl .ant-table .ant-table-container .ant-table-wrapper .ant-table-content {
  margin: 20px;
}
.leaflet-touch .leaflet-bar a {
  position: relative;
  width: 36px;
  height: 36px;
  /* line-height: 36px; */
}
.leaflet-touch .leaflet-bar a:before {
  position: absolute;
  content: "\f3df";
  font-family: bootstrap-icons !important;
  font-size: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.locationShowingWindowBtns .ant-popover .ant-popover-inner {
  padding: 5px;
}
.dashboard-card-row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px 0;
}
.dashboard-card {
  width: 11%;
  height: 110px;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 5px;
}
.dashboard-card:last-child {
  margin: 0;
}
.dashboard-card i {
  margin: 0 0 0 0;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
}
.dashboard-card b {
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.dashboard-card p {
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  display: block;
}
.dashboard-basic .running {
  background: #1acaa1;
}

.dashboard-basic .idle {
  background: #fccf47;
}

.dashboard-basic .stop {
  background: #fb5554;
}

.dashboard-basic .ex-idle {
  background: #ffa800;
}

.dashboard-basic .no-data {
  background: #797f82;
}

.dashboard-basic .total {
  background: #404e69;
}
.dashboard-basic .expired {
  background: #36badf;
}
.dashboard-basic .expiring-soon {
  background: #8e52c0;
}

.dashboard-basic .geofence-visit {
  background: #8e52c0;
}

.dashboard-basic .geofence-visit {
  background: #747a80;
}
.dashboard-panel {
  border: solid 1px #dddddd;
  border-radius: 0;
  width: 100%;
  margin-bottom: 20px;
}
.dashboard-panel .ant-card-body {
  padding: 10px !important;
}
.dashboard-panel .ant-card-head {
  background: #4d4e50;
  border-radius: 0;
  height: 45px;
  min-height: 45px;
}
.dashboard-panel .panel-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.dashboard-panel .panel-head h1 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 8px;
  padding: 0;
}
.dashboard-panel .panel-head .ant-select-selector {
  background: #ffffff;
  border: 0;
  border-radius: 3px;
  height: 30px;
  align-items: center;
  padding: 5px;
  width: 160px;
  margin: 0;
  top: -5px;
  margin-inline-end: 5px;
}
.dashboard-panel .panel-head .ant-select-selector input {
  font-weight: normal;
  font-size: 14px;
}
.dashboard-panel
  .panel-head
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  padding: 5px;
}
.dashboard-panel .panel-head .transparent-btn {
  background: transparent;
  border-radius: 0;
  border: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  padding: 0;

  top: -5px;
}
.dashboard-panel .panel-head .transparent-btn.topAlign {
  vertical-align: 6px;
  top: 0;
}

.status-tag {
  border-radius: 4px !important;
  padding: 0 3px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  border: 0;
  height: auto;
  padding: 0 8px;
  font-size: 13px;
  margin: 0 5px 0 0;
  border-inline-start: 0 !important;
  border-inline-end: 0 !important;
  min-height: 30px;
}
.status-tag:hover {
  color: #ffffff;
}
.status-tag::before {
  display: none !important;
}

/*.dashboard-card-row .total, .dashboard-card-row .running, .dashboard-card-row .stopped, .dashboard-card-row .idle, .dashboard-card-row .inactive, .dashboard-card-row .nodata {
  margin: 0 !important;
}*/
.dashboard-panel.ant-card .ant-card-head::before {
  display: none;
}
.ant-card.window-padding .ant-card-body {
  padding: 15px !important;
}
.dashboard-panel .body-toolbar .search-box .ant-input {
  height: 35px;
  margin: 0 0 0 8px;
}
.dashboard-panel .body-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 8px 0;
}

.dashboard-panel .body-toolbar .ant-radio-button span {
  font-size: 11px;
}
/* .status-tag b {
  display: block;
  margin: -10px 0 0 0;
} */
.ant-form-vertical .uploadFile .ant-form-item-label {
  height: 50px;
}

/* .ant-picker-now a{margin-left: 174px;} */

.changePassForm
  .ant-select.ant-select-in-form-item
  .ant-select-selector
  input::-webkit-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}
.changePassForm
  .ant-select.ant-select-in-form-item
  .ant-select-selector
  input::-ms-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}
.changePassForm
  .ant-select.ant-select-in-form-item
  .ant-select-selector
  input::-moz-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}

.changePassForm .ant-input::-webkit-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}
.changePassForm .ant-input::-ms-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}
.changePassForm .ant-input::-moz-input-placeholder {
  color: #8d8d8d !important;
  opacity: 1 !important;
}

.changePassForm
  .ant-select.ant-select-in-form-item.ant-select-multiple
  .ant-select-selection-placeholder {
  color: #8d8d8d !important;
}
.changePassForm
  .ant-select.ant-select-in-form-item.ant-select-multiple
  .ant-select-selection-item-remove {
  color: #8d8d8d !important;
}

.changePassForm
  .ant-select.ant-select-in-form-item
  .ant-select-selection-placeholder {
  color: #8d8d8d !important;
}

.temperature-dashboard {
  color: white;
}

.temperature-dashboard .temp-top-box {
  width: 92%;
  background: rgb(30, 51, 64);
  padding: 20px;
  display: flex;
  border-radius: 8px;
  margin-block-start: 16px;
  margin-block-end: 20px;
  margin-inline-start: 20px;
  margin-inline-end: 1px;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.25);
  padding: 15px;
  align-items: center;
  background: #1e3340;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #1e3340 0%,
    #15141c 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #1e3340 0%,
    #15141c 100%
  );
  background: radial-gradient(ellipse at center, #1e3340 0%, #15141c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3340', endColorstr='#15141c',GradientType=1 );
}
.temperature-dashboard .temp-top-box b {
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}
.temperature-dashboard .temp-top-box p {
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.temperature-dashboard .vehicle-details-panel {
  /* margin: 20px; */
  /* margin: 5px 0px 0px 20px; */

  margin-block-start: 5px;
  margin-inline-start: 20px;
  background: #1e3340;
  height: 500px;
  padding: 7px;
  border-radius: 8px;
}
.temperature-dashboard .vehicle-details-panel .header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}
.temperature-dashboard .temperature-alert-panel {
  margin-block-start: 24px;
  margin-block-end: 20px;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  background: #1e3340;
  height: 500px;
  border-radius: 8px;
  padding-top: 11px;
}
.temperature-dashboard .temperature-alert-panel .alert-block {
  background: url(./assets/images/alert-temperature-dashboard.png) no-repeat
    center top;
  background-size: 25%;
  text-align: center;
  margin: 30px auto 0 auto;
  height: 200px;
  padding: 100px 0 0 0;
  width: 80%;
}
.temperature-dashboard .temperature-alert-panel .alert-block .P {
  text-align: center;
  height: 100%;
}

/* Video Monitoring */
.video-monitoring .live-video-streaming-block {
  border: 1px solid #e0e0e0;
  height: 90vh;
  overflow: auto;
}
.video-monitoring .live-video-streaming-block .heading {
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-monitoring .playback-options-block {
  border: 1px solid #e0e0e0;
  height: 90vh;
  overflow: auto;
}
.video-monitoring .playback-options-block .heading {
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.black-modal-window .ant-modal-content {
  border-radius: 15px;
  background: #1e3340;
  max-height: 550px;
  padding: 10px;
  border: solid 1px #ffffff !important;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #1e3340 0%,
    #15141c 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #1e3340 0%,
    #15141c 100%
  );
  background: radial-gradient(ellipse at center, #1e3340 0%, #15141c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3340', endColorstr='#15141c',GradientType=1 );
}
.black-modal-window .ant-modal-header {
  background: transparent;
  padding: 0 0 10px 0;
  border-bottom: solid 1px #ffffff;
}
.black-modal-window .ant-modal-header .ant-modal-title {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 0 0 0;
}
.black-modal-window .ant-modal-header .modal-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.black-modal-window .ant-modal-header .ant-modal-title h1 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
}
.black-modal-window .ant-modal-header .ant-modal-title i {
  font-size: 25px;
  color: #ffffff;
  margin: 0 0 0 10px;
}
.black-modal-window .ant-modal-close {
  display: none !important;
}
.black-modal-window .ant-modal-header .modal-topbar .ant-select-selector,
.black-modal-window .ant-modal-header .modal-topbar .ant-input {
  height: 30px;
  border-radius: 3px;
  color: #666666;
  font-weight: normal;
  font-size: 13px;
}
.black-modal-window
  .ant-modal-header
  .modal-topbar
  .ant-select-selector
  .ant-select-selection-placeholder,
.black-modal-window
  .ant-modal-header
  .modal-topbar
  .ant-input
  .ant-select-selection-placeholder {
  height: 30px;
  line-height: 30px;
  color: #666666;
  font-weight: normal;
  font-size: 13px;
}
.black-modal-window
  .ant-modal-header
  .modal-topbar
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 30px;
}
.black-modal-window .ant-modal-header .modal-topbar .right-align {
  display: flex;
  align-items: center;
}
.sensors-container {
  display: flex;
  margin: 10px 0 0 0;
  flex-wrap: wrap;
}
.sensors-box {
  width: 24.1%;
  background: #ffffff;
  border-radius: 10px;
  padding: 8px 10px;
  position: relative;
  margin: 0 5px 10px 5px;
}
.sensors-box .ant-card-bordered {
  border: 0;
  border-radius: 0;
}
.sensors-box .sensors-info {
  display: flex;
  justify-content: flex-start;
}
.sensors-box .sensors-info .icon {
  background: #14131b;
  width: 34px;
  height: 34px;
  align-items: center;
  border-radius: 100%;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 25px 0 0;
}
.sensors-box .sensors-info .icon span {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #0090e7;
  text-align: center;
  color: #ffffff;
  font-size: 9px;
  font-weight: 400;
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  right: -7px;
  bottom: 5px;
}
.sensors-box .sensors-info b,
.sensors-box .sensors-info small {
  display: block;
}
.sensors-box .sensors-info b {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #000000;
  line-height: 100%;
}
.sensors-box .sensors-info small {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.video-monitoring .ant-tree-list {
  margin-left: 20px;
}

.sensors-container .sensors-box .btns-block {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.sensors-container .sensors-box .btns-block .btn {
  background: #128361;
  border-radius: 3px;
  padding: 0 8px;
  width: 23%;
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.sensors-container .sensors-box .btns-block .btn p {
  margin: 0;
  padding: 0;
}
.sensors-container .sensors-box .btns-block .btn p small {
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  display: block;
}
.sensors-container .sensors-box .btns-block .btn b {
  font-weight: 400;
}
.sensors-container .sensors-box .btns-block .btn:nth-child(2) {
  background: #009abb;
}
.sensors-container .sensors-box .btns-block .btn:nth-child(3) {
  background: #126183;
}
.sensors-container .sensors-box .btns-block .btn:nth-child(4) {
  background: #12833f;
}
.temp-report-container {
  padding: 20px;
}
.temp-report-container .form-row {
  display: flex;
}
.temp-report-container .sensors-field {
  width: 32%;
  margin-block-start: 0;
  margin-block-end: 15px;
  margin-inline-start: 0;
  margin-inline-end: 10px;
}
.temp-report-container .sensors-field .ant-select-selector,
.temp-report-container .sensors-field.ant-picker {
  border: 0;
  height: 40px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: none;
}
.temp-report-container
  .sensors-field
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}
.temp-report-container .sensors-field .ant-select-arrow,
.temp-report-container .sensors-field .ant-picker-suffix {
  color: #ffffff;
}
.temp-report-container .sensors-field .ant-select-selection-item {
  color: #ffffff;
}
.temp-report-container .sensors-field.ant-picker input {
  line-height: 40px;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}
.temp-report-container .sensors-field.ant-picker input::placeholder {
  color: #ffffff;
}
.temp-report-container .btn-row {
  text-align: center;
  margin: 15px 0;
}
.temp-report-container .btn-row .ant-btn {
  background: #0090e7;
  border: solid 1px #6c6c6c;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
}
.temp-report-container .sensors-col-wrapper {
  display: flex;
  margin: 0 0 15px 0;
}
.temp-report-container .sensors-col-wrapper .sensors-col {
  width: 25%;
  margin: 0;
  margin-inline-end: 2%;
}
.temp-report-container .sensors-col-wrapper .sensors-col:last-child {
  margin: 0;
}
.temp-report-container .sensors-col-wrapper .sensors-col .lbl {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.temp-report-container .sensors-col-wrapper .sensors-col .ant-input {
  height: 40px;
  border-radius: 8px;
  background: #fcfcfc;
  box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  color: #4b4b4b;
  border: solid 1px #cbcbcb;
}
.temp-report-container
  .sensors-col-wrapper
  .sensors-col
  .ant-input::placeholder {
  color: #4b4b4b;
}

/* .bg-image{ background: rgba(0, 0, 0, 0) ("../assets/images/bg.png") no-repeat scroll center center;} */
.bg-image {
  /* background: url(./assets/images/bg.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
*/
  height: 100%;
}

.bg-image .logo-image {
  display: block;
  margin: 0 auto;
  padding: 10px 0 10px 0px;
}

/*.trackeasy-features{ 
  display: flex; 
  align-items: center; 
  flex-wrap:wrap;
}*/
.trackeasy-features .live-tracking,
.trackeasy-features .dashboard,
.trackeasy-features .history-tracking,
.trackeasy-features .detail-tracking,
.trackeasy-features .alerts,
.trackeasy-features .masters,
.trackeasy-features .advance-dashboard,
.trackeasy-features .expiringsoon,
.trackeasy-features .reports,
.trackeasy-features .maintainance-dashboard,
.trackeasy-features .help {
  width: calc(23.5% - 8px);
  background: #f44236;
  padding: 20px;
  margin: 0 10px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 18px;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  transition: 0.6s;
}

.trackeasy-features .live-tracking:hover {
  background: #e43c31;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .dashboard {
  background: #e61b65;
}
.trackeasy-features .dashboard:hover {
  background: #d1185b;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .history-tracking {
  background: #364046;
}
.trackeasy-features .history-tracking:hover {
  background: #282f34;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .detail-tracking {
  background: #9b26af;
}

.trackeasy-features .detail-tracking:hover {
  background: #8a209c;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .alerts {
  background: #3d4fb4;
}

.trackeasy-features .alerts:hover {
  background: #3444a0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .masters {
  background: #2095f3;
}

.trackeasy-features .masters:hover {
  background: #1b88e0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .advance-dashboard {
  background: #01bbd3;
}

.trackeasy-features .advance-dashboard:hover {
  background: #03a9be;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .expiringsoon {
  background: #029587;
}

.trackeasy-features .expiringsoon:hover {
  background: #038175;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .reports {
  background: #4aaf4e;
}

.trackeasy-features .reports:hover {
  background: #429c46;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .maintainance-dashboard {
  background: #785446;
}

.trackeasy-features .maintainance-dashboard:hover {
  background: #68493c;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .help {
  background: #5e7c8b;
}

.trackeasy-features .help:hover {
  background: #526c79;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackeasy-features .live-tracking a,
.trackeasy-features .dashboard a,
.trackeasy-features .history-tracking a,
.trackeasy-features .detail-tracking a,
.trackeasy-features .alerts a,
.trackeasy-features .masters a,
.trackeasy-features .advance-dashboard a,
.trackeasy-features .expiringsoon a,
.trackeasy-features .reports a,
.trackeasy-features .maintainance-dashboard a,
.trackeasy-features .help a {
  text-decoration: none;
}
.trackeasy-features .live-tracking span,
.trackeasy-features .dashboard span,
.trackeasy-features .history-tracking span,
.trackeasy-features .detail-tracking span,
.trackeasy-features .alerts span,
.trackeasy-features .masters span,
.trackeasy-features .advance-dashboard span,
.trackeasy-features .expiringsoon span,
.trackeasy-features .reports span,
.trackeasy-features .maintainance-dashboard span,
.trackeasy-features .help span {
  display: block;
  margin-bottom: 36px;
}
.trackeasy-features .live-tracking h5,
.trackeasy-features .dashboard h5,
.trackeasy-features .history-tracking h5,
.trackeasy-features .detail-tracking h5,
.trackeasy-features .alerts h5,
.trackeasy-features .masters h5,
.trackeasy-features .advance-dashboard h5,
.trackeasy-features .expiringsoon h5,
.trackeasy-features .reports h5,
.trackeasy-features .maintainance-dashboard h5,
.trackeasy-features .help h5 {
  color: #ffffff;
}

.addressMap .ant-modal-content {
  padding: 0;
}
.addressMap .ant-modal-header {
  background: #0090e7;
  margin: 0 !important;
  min-height: 41px;
}

.leaflet-right {
  inset-inline-end: 0 !important;
  right: auto;
}
.arabic-map-container .leaflet-right {
  inset-inline-start: 0 !important;
  left: auto;
  right: 0;
}
/* .leaflet-google-mutant {
width: 100%;

} */

.settingMenu {
  border-start-start-radius: 0;
  border-start-end-radius: 10px;
  border-end-start-radius: 0;
  border-end-end-radius: 10px;
}
/* .dropdown-popover .ant-popover {
  left: 200px !important;
  right: auto !important;
}
.dropdown-popover .ant-popover-rtl {
  right: 200px !important;
  left: auto !important;
}  */
.menuWrapper {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
  width: 100%;
}
.arabicMenu {
  transform: translate(-50%, -50%);
  inset-inline-start: -50%;
  top: 50%;
  position: absolute;
  width: 100%;
}
.trackeasy-features .live-tracking,
.trackeasy-features .dashboard,
.trackeasy-features .history-tracking,
.trackeasy-features .detail-tracking,
.trackeasy-features .alerts,
.trackeasy-features .masters,
.trackeasy-features .advance-dashboard,
.trackeasy-features .expiringsoon,
.trackeasy-features .reports,
.trackeasy-features .maintainance-dashboard,
.trackeasy-features .help {
  display: inline-block;
}
.bgWrapper {
  background: url(./assets/images/bg.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}
.trackeasy-features {
  display: flex;
}

/* .ant-table-container {
  display: flex;
  min-height: 80vh;
} */

/*html, body{
  height: 100vh;
}

.ant-table-wrapper {
height: 100%;
overflow: hidden;
} 
.ant-spin-nested-loading {
height: 100%;
}  
.ant-spin-container {
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
}    
.ant-table {
max-height: 90%;
overflow: auto;
}
.app-sammek {
  height: 100%;
}*/

/* .ant-table-wrapper .ant-table-content table {
  height: 100vh;
}

.dataTable .ant-table-container {
  height: 72vh;
  
} */
/* 
.ant-table-tbody {
  height: 67vh;
} */
/* .ant-table-wrapper .ant-table-pagination.ant-pagination {
  position: absolute;
  right: 0;
  bottom: -115px;
} */

.ant-checkbox-wrapper span {
  font-size: 12px;
}

.ant-form-vertical .select-parameter-label .ant-form-item-label {
  position: relative !important;
  bottom: -8px !important;
  left: 0px !important;
  /* /* z-index: 99; */
}

.alertSelector .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dataTbl-wrapper .ant-table-wrapper {
  /* border: solid 1px #f0f0f0; */
  height: 100%;
}
/* .dataTbl-wrapper .list-tbl-height { max-height: 400px;} */
.dataTbl-wrapper .list-tbl-height .ant-table-body {
  height: 100vh;
}
.dataTbl-wrapper .list-tbl-height tbody.ant-table-body {
  height: auto;
}

.rightPanel-black .list-tbl-height .ant-table-body {
  height: 100vh;
}
.rightPanel-black .list-tbl-height tbody.ant-table-body {
  height: auto;
}

/* .list-tbl-height .ant-table-body { height: 100vh;} 
.list-tbl-height tbody.ant-table-body { height: auto;} */

.left-panel .clearallmainBtn {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -40px;
}

.dataTbl-wrapper .ant-table-placeholder .ant-table-cell {
  border: 0;
}
.dashboard-card-wrapper.ant-row {
  flex-flow: nowrap;
  margin: 0 0 20px 0;
}
.dashboard-card-wrapper.ant-row.mrgnNone {
  margin: 0;
}
.dashboard-card-wrapper.ant-row .dashboard-panel {
  height: 100%;
}
.dashboard-card-wrapper.ant-row .ant-col-12 {
  max-width: 49%;
  margin-inline-end: 1%;
}
.dashboard-card-wrapper.ant-row .ant-col-12:nth-child(2) {
  margin-inline-start: 1%;
}
.dashboard-card-row .running,
.dashboard-card-row .idle,
.dashboard-card-row .total {
  margin-inline-end: 5px !important;
}
.map-whiteBtn .ant-float-btn-group {
  inset-inline-start: 1%;
}
.arabic-map-container .map-whiteBtn .ant-float-btn-group {
  inset-inline-end: 3%;
  direction: ltr;
}
.arabic-map-container .map-whiteBtn {
  position: absolute;
  right: 40px;
  top: 10px;
}
.form-offlineAlerts {
  inset-inline-start: 12px;
}

/* .ant-popover .ant-popover-inner .popconfirm {
  padding-block-start: 12px !important;
  padding-block-end: 12px !important;
  padding-inline-start: 12px !important;
  padding-inline-end: 12px !important;
 
} */
/* .map-setting-lbl label {
  margin:17px 0 0 0;
} */

.history-tracking-panel.ant-card .ant-card-head {
  padding: 0 !important;
}

.history-heading {
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
}
.history-heading h5 {
  margin: 0;
}

.liveTracking-search.ant-input-search .ant-input {
  height: 36px !important;
  border-radius: 6px !important;
}
.listInputRow .ant-select-selector {
  height: 36px !important;
}
.liveTracking-search.ant-input-search .ant-input-group-addon {
  display: none;
}

.liveTrackingTimerCss .ant-select-arrow {
  top: 31px;
}

/* .float-button {
  width: 32px;
  height:32px;
  min-width: 32px;
  min-height: 32px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
}
.float-button .ant-float-btn {
  width: auto;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  border-radius: 6px;
}
.float-button .ant-float-btn svg {
  font-size: 14px;
}
.float-button .ant-float-btn:hover {
  background-color: #ffffff;
} */

/* new inner dashboard */
.menuWrapper a img:hover {
  opacity: 0.7;
}
.menuWrapper a.menu-disabled img {
  opacity: 0.1;
  cursor: not-allowed;
}
.menu-child {
  position: absolute;
  top: calc(44% - 27.2px);
  left: calc(50% + 102.8px);
  border-radius: 50%;

  width: 412.9px;
  height: 412.9px;
  transform: rotate(65.74deg);
  transform-origin: 0 0;
  /* background: url(../images/circle.png) no-repeat; */
  background-size: 100%;
}
.menu-item {
  position: absolute;
  top: calc(44% + 39.4px);
  left: calc(50% - 207px);
  width: 412.9px;
  height: 230.9px;
}
.ellipse-icon,
.group-icon,
.menu-inner {
  position: absolute;
  top: calc(44% - 133px);
  left: calc(50% - 385px);
  width: 770px;
  height: 570.6px;
}
.ellipse-icon,
.group-icon {
  height: 440.1px;
}
.group-icon {
  top: calc(44% + 123.9px);
  left: calc(50% - 207px);
  width: 123.2px;
  height: 147.1px;
  object-fit: cover;
}
.menu-child1,
.menu-child2 {
  position: absolute;
  top: calc(44% + 39.5px);
  left: calc(50% - 150.4px);
  width: 143.9px;
  height: 135.2px;
  object-fit: cover;
}
.menu-child2 {
  top: calc(44% + 39.9px);
  left: calc(50% + 6.4px);
  width: 144.2px;
  height: 135.7px;
}
.menu-child3,
.menu-child4 {
  position: absolute;
  top: calc(44% + 124.9px);
  left: calc(50% + 83.1px);
  width: 122.8px;
  height: 147.3px;
  object-fit: cover;
}
.menu-child4 {
  top: calc(44% + 138.9px);
  left: calc(50% + 268.7px);
  width: 116.3px;
  height: 167.5px;
}
.menu-child5,
.menu-child6 {
  position: absolute;
  top: calc(44% - 22.1px);
  left: calc(50% - 361.4px);
  width: 164px;
  height: 177.1px;
  object-fit: cover;
}
.menu-child6 {
  top: calc(44% - 117.9px);
  left: calc(50% - 254.2px);
  width: 176.2px;
  height: 158.8px;
}
.menu-child7,
.menu-child8 {
  position: absolute;
  top: calc(44% + 139.6px);
  left: calc(50% - 385px);
  width: 116.2px;
  height: 167.5px;
  object-fit: cover;
}
.menu-child8 {
  top: calc(44% - 133px);
  left: calc(50% - 85.8px);
  width: 168px;
  height: 111px;
}
.menu-child10,
.menu-child9 {
  position: absolute;
  top: calc(44% - 118.8px);
  left: calc(50% + 75.7px);
  width: 176px;
  height: 158.1px;
  object-fit: cover;
}
.menu-child10 {
  top: calc(44% - 24.4px);
  left: calc(50% + 195.6px);
  width: 164.5px;
  height: 177.1px;
}
.logo-w-1-icon {
  position: absolute;
  top: 51px;
  /*left: calc(50% - 145px);*/
  /*width: 295px;*/
  height: 110px;
  object-fit: cover;
  left: 0;
  right: 0;
  margin: 0 auto;
}
/*.logo-w-1-icon {
  position: absolute;
  top: 51px;
  left: calc(50% - 231px);
  width: 469px;
  height: 147px;
  object-fit: cover;
}
*/
/* .menuWrapper {
  width: 100%;
  position: relative;
  height: 100%;
  background-image: url("../assets/images/innerDashboard/menuBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; */
/* } */

.add-checkpoint-wrapper {
  margin: 20px 0 0 0;
}
.add-checkpoint-wrapper .add-checkpoint-inner {
  background: transparent;
  padding:0;
}
.add-checkpoint-wrapper .checkpoint-list {
  background: transparent;
  display: flex;
  justify-content: start;
  align-items:flex-start;
  border-top: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
  padding: 15px 5px;
  position: relative;
}
.add-checkpoint-wrapper .checkpoint-list.active-checkpoint::before {

  background:#1677ff;
  width: 2px;
  height: 70px;
  position: absolute;
  content: '';
  left: -10px;
  bottom: 10px;
  border-radius: 8px;
}
.add-checkpoint-wrapper .checkpoint-list span {
  margin: 5px 15px 0 0;
  border-radius: 100%;
  min-width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  align-self: flex-start;
  border: solid 2px #eeeeee;
}
.add-checkpoint-wrapper .checkpoint-list p {
  margin: 0;
  padding:0;
}
.add-checkpoint-wrapper .checkpoint-list p b {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.add-checkpoint-wrapper .checkpoint-list p small {
  display: block;
  font-size: 12px;
}
.add-checkpoint-wrapper .checkpoint-list p small.date-time {
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
}
.add-checkpoint-wrapper .checkpoint-list .checkpoint-close {
  position: absolute;
  right:-5px;
  top: 10px;
  visibility:hidden;
}
.add-checkpoint-wrapper .checkpoint-list:hover .checkpoint-close {
  visibility:visible;
}
.add-checkpoint-wrapper .checkpoint-list:hover .checkpoint-close span {
  border: 0;
  border-radius: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin: 0;
}
.add-checkpoint-wrapper .checkpoint-list .invalid-loc-alert {
  color:#f00;
  position: absolute;
  right:-3px;
  bottom: 10px;
}

.add-checkpoint-wrapper .checkpoint-list .invalid-loc-alert span {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  font-size: 16px;

}

.add-checkpoint-btn {
  color: #1677ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin: 15px 0 0 0;
}
.add-checkpoint-btn button {
  background: transparent;
  box-shadow: none;
  color: #1677ff;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0;
}
.add-checkpoint-btn button span.ant-btn-icon {
  font-size: 18px;
  color: #1677ff;
}
.leftWhite-form-panel .add-location.locationInput {
  display: flow;
}
/*.ant-select-selector {
  inset-inline-start: 11px;
}*/
.ant-select-selection-overflow-item {
top: 5px !important;
}
.accordion-list {
  background: rgb(24, 23, 34);
display: flex;
justify-content: space-between;
margin-bottom: 5px;
padding:3px 10px;
color: #ffffff;
font-size:16px;
font-weight: 600;
border-radius: 8px;
line-height: 32px;
}
.accordion-list button {
  background: transparent;
  padding:0;
  border: 0;
  color: #ffffff;
  font-size: 20px;
  line-height: 85%;
}
.accordion-list .ant-btn-default:not(:disabled):hover {
  color: #ffffff;
  border: 0;
}
.accordion-list button span {
  margin: -2px 0 0 0;
}
.ant-select-single .ant-select-selector  {
  font-family: Inter;
  font-size:13px;
}
.sml-search-input .ant-input {
  font-family: Inter;
  font-size:13px;
}
.listInputRow .ant-select-single .ant-select-selector .ant-select-selection-placeholder {

  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
}
.action-btn {
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 0 8px;
}
.action-btn:hover {
  background: transparent;
  box-shadow: none;
}
.action-btn.deleteBtn {
  color:rgb(240, 57, 57);
}
.pageTitle {
  color: #0e1726;
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 15px 0;
  font-family: Inter;
}
.collapse-panel {
  border: 0;
}
.collapse-panel .ant-collapse-item {
  border: 0;
}
.collapse-panel .ant-collapse-item .ant-collapse-header {
  background: rgb(24, 23, 34);
display: flex;
justify-content: space-between;
margin-bottom: 5px;
padding:3px 10px;
color: #ffffff;
font-size:16px;
font-weight: 600;
border-radius: 8px;
line-height: 32px;
border: 0;
}
.collapse-panel .ant-collapse-content {
  border: 0;
}
.collapse-panel .ant-collapse-expand-icon {
  position: absolute;
  right: 0;
  top: 8px;
}

.delivery-dashboard-card {
  width: 13.6%;
  height: 55px; 
  padding: 10px;
}

.delivery-dashboard-card-row {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px 15px 20px;
}

.delivery-dashbord-header {
  position: sticky;
  z-index: 1;
  align-items: center;
  height: 60px;
  background: #181722;
  color: white;
  padding: 0px 24px;
  margin-left: 20px;
  margin-right: 12px;
}

.delivery-dashboard-card i {
  margin: 0 0 0 0;
  /* text-align: center; */
  color: #ffffff;
  font-size: 25px;
}

.delivery-dashboard-card span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin: -22px;
  padding: 0;
  color: #ffffff;
  text-align: center;
}

.delivery-dashboard-panel {
  border: solid 1px #dddddd;
  border-radius: 0;
  margin-bottom: 20px;
}
.delivery-dashboard-panel .ant-card-body {
  padding: 10px !important;
}
.delivery-dashboard-panel .ant-card-head {
  background: #4d4e50;
  border-radius: 0;
  height: 45px;
  min-height: 45px;
}
.delivery-dashboard-panel .panel-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.delivery-dashboard-panel .panel-head h1 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 8px;
  padding: 0;
}

.statusDropdown, .statusDropdown .ant-select-selector { 
  height: 33px !important;
}
.statusDropdown .ant-select-selection-placeholder {
  line-height: 33px !important;
}
.dash-countBlock {
  display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dash-countBlock i {
  font-size:30px;
    margin: 0 10px 0 0;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
}
.dash-countBlock span {
  margin: 0;
  text-align: left;
}
.dash-countBlock span p {
  margin: 0;
  padding: 0;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

/* @keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
} */
.download-btn .ant-image  {
  margin: 0 0 0 8px;
}

.dashboard-select, .dashboard-select .ant-select-selector { 
  height: 36px !important;
}

.dashboard-select .ant-select-selection-item {
  line-height: 36px !important;
}

.icon-image{
  width: 20px; height: 35px;
}

/* height: "100vh",
position: "fixed",
minHeight: "100vh",
background: "#181722",
boxShadow: "5px 0px 4px 0px rgba(0, 0, 0, 0.60)",
zIndex: "1",

// left: 0,
insetInlineStart: 0,
top: 0,
bottom: 0, */

.leftSlider {
  background: rgba(24, 23, 34) !important;
  box-shadow: rgba(0, 0, 0, 0.6) 5px 0px 4px 0px;
}
.leftSlider .logoBlock {
  background-color:#1f212d;
}
.leftSlider .innerBigLogo {
  background-color: rgba(31, 33, 45);
  box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.45);
}
.leftSlider .logoBlock .ant-image-img {
  background-color: transparent;
  box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.45);
}
.changePassLayout .changePassBtn:first-child {
  background: #181722;
}
.uploadBtn {
  font-size: 14px;
  height: 32px;
  padding: 0px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.uploadBtn svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
.exportDropdown {
  border: 0 !important;
  background-color: #87d068 !important;
  color: #ffffff;
}
.exportDropdown:hover, .exportDropdown:disabled:hover {
  color: #ffffff;
}
.exportDropdown:disabled {
  background-color: #87d068 !important;
  opacity: 0.5 !important;
  border: 0 !important;
  color: #ffffff;
}
.exportDropdown .anticon-download svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
.exportDropdown svg {
  width: 1em !important;
  height: 1em !important;
}

.innerGridTbl .ant-table-tbody{
  background-color:  #cef6cb !important;
}
.innerGridTbl .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #87d068 !important;
}

.innerGridTbl .ant-table-tbody > tr.ant-table-row:hover td {
  background-color: #cef6cb !important;
}

.fuel-info-scroll {
  min-height: 280px;
  overflow-y: scroll;
  max-height: 280px;
}
.fuel-info-block {
  margin: 15px 0 0 0;
  margin-inline-end: 5px;
  cursor: pointer;
}
.fuel-info-block .fuel-content p {
  display: flex;
  justify-content: space-between;
  color: #000000;
  line-height: 16px;
  margin: 0 0 10px 0;
}
.fuel-info-block .fuel-content p span {
  width: 35%;
    text-align: start;
    font-size: 13px;
    font-weight: 500;
}
.fuel-info-block .fuel-content p small {
  text-align: end;
  font-size: 12px;
  margin-inline-start: 8px;
}
.BlueTitle {
  background: #0090e7;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  margin:10px 0 0 0;
}
/* .innerGridTbl .ant-select .ant-select-arrow{
  top: 55%;
} */

/* .innerGridTbl .ant-table-thead{
  background-color: #1a2c39 !important;
} */

/* Drive7 Theme CSS */

.drive7-wrapper .leftSlider {
  background: #284a63 !important;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 0px 4px 0px;
}


.drive7-wrapper .leftSlider .logoBlock {
  background-color: transparent;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.drive7-wrapper .leftSlider .innerBigLogo {
  background-color: #284a63;
  box-shadow:none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.drive7-wrapper .leftSlider .logoBlock .ant-image-img {
  box-shadow:none;
}
.drive7-wrapper  .ant-menu-dark {
  background: #284a63 !important;
}
.drive7-wrapper  .ant-menu-dark {
  background: #284a63 !important;  
}
.drive7-wrapper  .ant-menu-dark .ant-menu-vertical {
  background: #284a63 !important;
}
.drive7-wrapper .ant-menu-submenu-popup
.ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {  
  background: #284a63 !important;
}
.drive7-wrapper .headerFixed {
  background: #284a63;
}


.drive7-wrapper .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #0870b9 !important;
}
.drive7-wrapper .ant-btn-primary {
  background-color: #0870b9; 
}
.drive7-wrapper .ant-btn-primary:not(:disabled):hover {
  background-color: #117eca;
}
.drive7-wrapper .ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #0870b9 !important;
}


.drive7-wrapper ::-webkit-scrollbar-thumb {
  background-color: #0870b9;
  -webkit-box-shadow: inset 0 0 6px rgb(28, 119, 176);
}

.drive7-wrapper :where(.css-dev-only-do-not-override-12upa3x).ant-menu-dark .ant-menu-item-selected, 
.drive7-wrapper :where(.css-dev-only-do-not-override-12upa3x).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #0870b9;
}
.drive7-wrapper .accordion-list {
  background: #284a63;
}
.drive7-wrapper .liveTrackingTimerCss .refresh-btn {
  background: #0870b9;
}
.drive7-wrapper .ant-tooltip.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #0870b9;
}
.drive7-wrapper .ant-tooltip-inner {
  background:  #0870b9 !important;
}
.drive7-wrapper .ant-tooltip {
  --antd-arrow-background-color: #0870b9 !important; 
}
.drive7-wrapper .imgupload {
  background-color: #284a63 !important;
}
.drive7-wrapper .right-menu-panel .list-bottom-btns button {
  background: #0870b9;
}
.drive7-wrapper .formCancelButton {
  background: #d9d9d9 !important;
}
.drive7-wrapper .changePassHeadline {
  background-color: #284a63;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 4px 0px;
}
.drive7-wrapper .changePassForm {
  background-color: #284a63 !important;
}
.drive7-wrapper .changePassForm .ant-input {
  background-color: #284a63;
}
.drive7-wrapper .addButton {
 border: 0;
}
.drive7-wrapper .exportDropdown {
  border: 0 !important;
  background-color: #c1198a !important;
  color: #ffffff;
}
.drive7-wrapper .exportDropdown:hover, .drive7-wrapper .exportDropdown:disabled:hover {
  color: #ffffff;
}
.drive7-wrapper .exportDropdown:disabled {
  background-color: #c1198a !important;
  opacity: 0.5 !important;
  border: 0 !important;
  color: #ffffff;
}
.drive7-wrapper .ant-tabs-tab-active span {
  color: #0870b9 !important;
}
.drive7-wrapper .changePassForm.ant-form-vertical .ant-form-item-label > label {
  background: #284a63;
}
.drive7-wrapper .changePassLayout .changePassBtn:first-child {
  background: #284a63;
}
.drive7-wrapper .collapse-panel .ant-collapse-item .ant-collapse-header {
  background: #284a63;
}